import React from "react";
import { useTranslation } from "react-i18next";

import style from "./bmp-workflow-quota.scss";
import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import StaticTable from "components/support/api-guide/StaticTable";

import testIds from "testIds.json";

interface Props {
    setShowBmpQuotaDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

interface Quatas {
    type: string;
    target: string;
    evaluationPeriod: string;
    value: string;
    notes: string;
}

const Quatas: Quatas[] = [
    {
        type: "Workflow executions",
        target: "Tenant",
        evaluationPeriod: "Per day",
        value: "10000",
        notes: "Based on ~5k report import/day",
    },
    {
        type: "Concurrent executions",
        target: "Tenant",
        evaluationPeriod: "Instant",
        value: "10",
        notes: "A guess to start with. This is a parameter that may be adjusted to reduce/increase the load on BMP in real time.",
    },
    {
        type: "Finish",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "Unlimited",
        notes: "",
    },
    {
        type: "Start",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "Unlimited",
        notes: "",
    },
    {
        type: "Add delay",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "Unlimited",
        notes: "Might not make sense to limit. A bit unclear. Depends on how delay is implemented.",
    },
    {
        type: "Condition",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "100000",
        notes: "Checking conditions should be relatively cheap and shouldn’t cause stress against rest of BMP. Should be proportional to total workflow execution per day.",
    },
    {
        type: "Set value",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "100000",
        notes: "Uses external resources in that the item creates new or modifies values in Redis.",
    },
    {
        type: "Server message",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "100000",
        notes: "A high-risk Item. We can start by allowing effectively only one Item of this type to be executed per workflow execution and move from there.",
    },
    {
        type: "Send email",
        target: "Tenant/Item",
        evaluationPeriod: "Per day",
        value: "100000",
        notes: "A high-risk Item. We can start by allowing effectively only one Item of this type to be executed per workflow execution and move from there. One Item/workflow execution should be the starting point.",
    },
];

const BmpWorkflowQuota = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    const columns = [
        { value: t("WorkflowsTable.bmpWorkflowQuota.type"), className: style.type },
        { value: t("WorkflowsTable.bmpWorkflowQuota.target"), className: style.target },
        { value: t("WorkflowsTable.bmpWorkflowQuota.evaluationPeriod"), className: style.evaluationPeriod },
        { value: t("WorkflowsTable.bmpWorkflowQuota.value"), className: style.value },
        { value: t("WorkflowsTable.bmpWorkflowQuota.notes"), className: style.notes },
    ];
    const cells: (string | JSX.Element | null)[][] | undefined = [];
    Quatas.forEach((quota, index) => {
        cells.push([
            <div key={index}>{quota.type}</div>,
            <div key={index}>{quota.target}</div>,
            <div key={index}>{quota.evaluationPeriod}</div>,
            <div key={index}>{quota.value}</div>,
            <div key={index}>{quota.notes}</div>,
        ]);
    });

    return (
        <div className={style.quotaContainer}>
            <StaticTable
                headers={columns}
                cells={cells}
                testId={testIds.workArea.tenant.viewTenantDialog.licenseOverview.table}
            />
            <ButtonContainer>
                <Button
                    variant={"PRIMARY"}
                    onClick={() => props.setShowBmpQuotaDialog(false)}
                    testId={testIds.common.dialog.primaryButton}
                >
                    {t("Common.ok")}
                </Button>
            </ButtonContainer>
        </div>
    );
};

export default BmpWorkflowQuota;
