import classNames from "classnames";
import * as React from "react";
import { connect, ConnectedProps } from "react-redux";
import { Column, Row, useBlockLayout, useExpanded, useResizeColumns, useSortBy, useTable } from "react-table";
import ReactTooltip from "react-tooltip";

import style from "./table.scss";
import EmptyStateIcon from "components/icons/EmptyState";
import { viewActionsContext } from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { TableData } from "domain/table";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import * as TableRepository from "services/table/tableRepository";
import { StoreState } from "store";
import { RepositoryKey } from "utils/repository";

const TABLE_CONTAINER_SCALING_FACTOR = 0.52;

// See https://github.com/DefinitelyTyped/DefinitelyTyped/tree/master/types/react-table#configuration-using-declaration-merging.
declare module "react-table" {
    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface HeaderGroup<D extends object = {}>
        extends ColumnInstance<D>,
            UseTableHeaderGroupProps<D>,
            UseResizeColumnsColumnProps<D>,
            UseSortByColumnProps<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface TableOptions<D extends object = {}>
        extends UseResizeColumnsOptions<D>,
            UseSortByOptions<D>,
            UseExpandedOptions<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface TableState<D extends object = {}>
        extends UseResizeColumnsState<D>,
            UseSortByState<D>,
            UseExpandedState<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface ColumnInterface<D extends object = {}>
        extends UseResizeColumnsColumnOptions<D>,
            UseSortByColumnOptions<D> {
        className?: string;
        fixedToLeft?: boolean;
        fixedToRight?: boolean;
    }

    // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-empty-interface
    export interface Row<D extends object = {}> extends UseExpandedRowProps<D> {}

    // eslint-disable-next-line @typescript-eslint/ban-types
    export interface TableInstance<D extends object = {}> extends UseExpandedInstanceProps<D> {
        isLoading: boolean;
    }
}

interface TableProps<T extends TableData> {
    data: T[];
    columns: Column<T>[];
    loaded: boolean;
    tableIdentity: RepositoryKey;
    failureMessage?: string | JSX.Element | undefined;
    scrollTo?: number;
    tooltips?: boolean;
    emptyMessage?: string | JSX.Element;
    inDialogBox?: boolean;
    testId?: string;
    autoResizeAdjustmentClass?: string;
    loading?: boolean;
    header?: boolean;
    dialogHeight?: number;
    noAction?: boolean;
    showSidePanel?: boolean;
    setIsSidePanelOpen?: (isOpen: boolean) => void;
    setSelectedRow?: React.Dispatch<React.SetStateAction<Row<T> | undefined>>;

    /**
     * If this isn't defined, column sorting is disabled.
     */
    sortCallbacks?: {
        onChange: (column: string, ascending: boolean) => void;
        onDisable: () => void;
    };
    hiddenColumns?: string[];
}

/**
 * Derive the width of column from the provided element width.
 *
 * @param percentage    Required width
 * @param element       HTML element
 * @returns             derived width of column from the provided element.
 */
export function deriveColumnWidth(percentage: number, element: React.RefObject<HTMLElement>): number {
    const tableContainerWidth = element.current?.offsetWidth ? element.current?.offsetWidth : 800;
    const columnWidth = Math.round((tableContainerWidth * percentage) / 100);
    return columnWidth > 100 ? columnWidth : 100;
}

export interface SortState {
    state: {
        sortBy: {
            id: string;
            desc: boolean;
        }[];
    };
}

export function createSortByHeaderSuffix(sortState: SortState, name: string): string {
    const sortBy = sortState.state.sortBy;
    // It seems that sortBy is never null but let's play it safe.
    // SortBy is empty when there is no active sorting. That is, when user has never clicked on any of the columns or
    // cycled through ascending, descending, and then to no sorting at all.
    if (sortBy == null || sortBy.length === 0) {
        return "";
    }
    const column = sortBy[0];
    if (column.id !== name) {
        return "";
    }
    return column.desc ? "descending" : "ascending";
}

/**
 * Derive the necessary changes to have desired columns visible without modifying columns that have desired visibility.
 *
 * @param toBeHidden IDs for columns that should be hidden, whether they already are or not.
 * @param hidden IDs for columns that are already hidden.
 * @returns map columns IDs to desired visibility state. True when column should be visible.
 */
export function deriveColumnVisibilityChanges(toBeHidden: string[] = [], hidden: string[] = []): Map<string, boolean> {
    const result = new Map<string, boolean>();
    const toBeHiddenIds = new Set(toBeHidden);
    const hiddenIds = new Set(hidden);
    toBeHidden.filter((each) => !hiddenIds.has(each)).forEach((each) => result.set(each, false));
    hidden.filter((each) => !toBeHiddenIds.has(each)).forEach((each) => result.set(each, true));

    return result;
}

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

function Table<D extends TableData>(props: TableProps<D> & ConnectedProps<typeof connector>): JSX.Element {
    const { data, failureMessage, loaded, tableIdentity, emptyMessage, loading, inDialogBox } = props;

    if (failureMessage !== undefined && failureMessage !== "") {
        return <div className={style.failureMessage}>{failureMessage}</div>;
    }

    if (!loaded) {
        return <LoadingIndicator />;
    }

    const computeAvailableSpace = (): number => {
        if (props.dialogHeight) {
            return props.dialogHeight;
        } else {
            return Math.round(window.innerHeight * TABLE_CONTAINER_SCALING_FACTOR);
        }
    };

    const { current: initialState } = React.useRef(TableRepository.getTableState(tableIdentity));
    const { current: columns } = React.useRef(props.columns);
    const scrollRef: React.RefObject<HTMLTableRowElement> = React.createRef<HTMLTableRowElement>();
    const tableContainerRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();
    const [dragEnd, setDragEnd] = React.useState<boolean>(false);
    const [draggedColumn, setDraggedColumn] = React.useState("");
    const sortEnabled = props.sortCallbacks != null;
    const { getTableProps, state, headerGroups, rows, prepareRow, toggleHideColumn } = useTable<D>(
        {
            columns,
            data,
            initialState,
            manualSortBy: true,
            disableMultiSort: true,
            disableSortBy: !sortEnabled,
            autoResetExpanded: false,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            getSubRows: (row: any) => row.tenants || row.subLicenses || [],
        },
        useBlockLayout,
        useResizeColumns,
        useSortBy,
        useExpanded
    );

    // Here it's important that we don't invoke toggleHideColumn when it's
    // not necessary. If we always invoke it, for example to hide column n
    // when it's already hidden, React will end up in an infinite loop.
    deriveColumnVisibilityChanges(props.hiddenColumns, state.hiddenColumns).forEach((visible, id) =>
        toggleHideColumn(id, !visible)
    );

    React.useEffect(() => {
        if (props.sortCallbacks == null) {
            return;
        }
        // Since all tables need to know the current sort configuration but
        // information resides here in a child component, we need to
        // communicate that somehow.This solution might not be ideal but it's
        // better than reading table state in the parent and passing it here
        // to the child because here we need to update the state.
        if (state.sortBy == null || state.sortBy.length === 0) {
            props.sortCallbacks.onDisable();
        } else {
            const column = state.sortBy[0];
            props.sortCallbacks.onChange(column.id, !column.desc);
        }
    }, [state.sortBy]);

    const scrollToRef = () => {
        const currentScrollRef = scrollRef.current;
        if (currentScrollRef) {
            currentScrollRef.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    React.useEffect(() => {
        if (props.scrollTo) {
            scrollToRef();
        }
    }, [props.scrollTo]);

    React.useEffect(() => {
        const stateWithoutExpanded = {
            ...state,
            expanded: {},
        };

        TableRepository.setTableState(stateWithoutExpanded, tableIdentity);
        for (const headerGroup of headerGroups) {
            for (const column of headerGroup.headers) {
                if (column.isResizing) {
                    setDraggedColumn(column.id);
                    setDragEnd(true);
                    return;
                }
            }
        }
    });

    const sendColumnResizeEvent = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
        // Without this, resizing also triggers column sort.
        event.stopPropagation();
        if (dragEnd) {
            usageStatisticsService.sendEvent({
                category: Category.REPORTS,
                action: Action.CHANGE_COLUMN_WIDTH,
                label: tableIdentity.toString() + " " + draggedColumn,
            });
        }
    };

    const viewSidePanelEvent = () => {
        if (props.setIsSidePanelOpen) {
            props.setIsSidePanelOpen(true);
        }
    };

    const [tableHeight, setTableHeight] = React.useState<number>();
    const adjustTableHeight = React.useCallback(() => {
        setTableHeight(computeAvailableSpace());
    }, []);
    window.addEventListener("resize", adjustTableHeight);

    React.useEffect(() => {
        setTableHeight(computeAvailableSpace());
        return () => {
            window.removeEventListener("resize", adjustTableHeight);
        };
    }, [loading]);

    const isLastSubRow = (rowId: string) => {
        const parentRow = rows.filter((row) => {
            if (row.subRows.length > 0) {
                return row.subRows.some((row) => row.id === rowId);
            }
        });

        if (parentRow.length > 0) {
            return parentRow[0].subRows[parentRow[0].subRows.length - 1].id === rowId;
        }
    };

    const isOnlyRow = () => {
        return rows.filter((row) => row.depth === 0).length === 1;
    };

    const tableHasFixedColumn = headerGroups[0].headers.some((header) => header.fixedToLeft === true);

    React.useEffect(() => {
        const tableContainer = tableContainerRef.current;

        if (!tableContainer) {
            return;
        }

        const handleScroll = () => {
            if (!tableContainer) {
                return;
            }

            if (tableContainer.scrollLeft > 0) {
                tableContainer.classList.add(style.scrolledTableContainer);
            } else {
                tableContainer.classList.remove(style.scrolledTableContainer);
            }
        };

        tableContainer.addEventListener("scroll", handleScroll);

        return () => {
            tableContainer?.removeEventListener("scroll", handleScroll);
        };
    }, [tableContainerRef]);

    const viewActionsForNoRecord = props.noAction ? null : React.useContext(viewActionsContext);
    return (
        <>
            {rows.length === 0 && (
                <div className={style.emptyStateMessageContainer}>
                    <div className={style.emptyStateMessage}>
                        {!inDialogBox ? (
                            <EmptyStateIcon
                                ellipseBackgroundColor={props.theme.emptyStateEllipseBackgroundColor}
                                biggestCircleBackgroundColor={props.theme.emptyStateBiggestCircleBackgroundColor}
                                smallestCircleBackgroundColor={props.theme.emptyStateSmallestCircleBackgroundColor}
                                personBackgroundColor={props.theme.emptyStatePersonColor}
                                personShirtColor={props.theme.emptyStatePersonShirtColor}
                                personSleeveColor={props.theme.emptyStatePersonSleeveColor}
                                ellipseBackgroundOpacity={props.theme.emptyStateEllipseBackgroundOpacity}
                                biggestCircleBackgroundOpacity={props.theme.emptyStateBiggestCircleBackgroundOpacity}
                                smallestCircleBackgroundOpacityFirst={
                                    props.theme.emptyStateSmallestCircleBackgroundOpacityFirst
                                }
                                smallestCircleBackgroundOpacitySecond={
                                    props.theme.emptyStateSmallestCircleBackgroundOpacitySecond
                                }
                                smallestCircleBackgroundOpacityThird={
                                    props.theme.emptyStateSmallestCircleBackgroundOpacityThird
                                }
                            />
                        ) : undefined}
                        {emptyMessage && <TextBlock disableBottomSpacing={true}>{emptyMessage}</TextBlock>}
                        {viewActionsForNoRecord && <div className={style.viewActions}>{viewActionsForNoRecord}</div>}
                    </div>
                </div>
            )}
            <div
                className={classNames(style.tableContainer)}
                style={{ minHeight: tableHeight }}
                ref={tableHasFixedColumn ? tableContainerRef : null}
            >
                {props.tooltips === true ? (
                    <ReactTooltip type="light" className={style.tooltip} clickable={true} />
                ) : (
                    ""
                )}
                <table {...getTableProps()} className={style.table} data-testid={props.testId}>
                    {(props.header ?? true) && (
                        <thead>
                            {headerGroups.map((headerGroup, rowIndex) => (
                                <tr {...headerGroup.getHeaderGroupProps()} key={rowIndex}>
                                    {/*
                                        Defining title as undefined removes built-in tooltip when hovering over columns. By
                                        default it's "Toggle SortBy".
                                    */}
                                    {headerGroup.headers.map((column, cellIndex) => (
                                        <th
                                            {...column.getHeaderProps(
                                                column.getSortByToggleProps({ title: undefined })
                                            )}
                                            className={classNames({
                                                [style.fixedColumn]: column.fixedToRight || column.fixedToLeft,
                                                [style.fixedToRight]: column.fixedToRight,
                                                [style.fixedToLeft]: column.fixedToLeft,
                                            })}
                                            key={cellIndex}
                                        >
                                            <span>{column.render("Header")}</span>
                                            {column.canResize && (
                                                <span
                                                    {...column.getResizerProps()}
                                                    className={column.isResizing ? style.resizing : style.resizer}
                                                    onClick={sendColumnResizeEvent}
                                                />
                                            )}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                    )}
                    <tbody>
                        {rows.length > 0 &&
                            rows.map((row, rowIndex) => {
                                prepareRow(row);

                                return (
                                    <>
                                        <tr
                                            onClick={() => {
                                                if (props.showSidePanel) {
                                                    viewSidePanelEvent();
                                                    if (props.setSelectedRow) {
                                                        props.setSelectedRow(row);
                                                    }
                                                }
                                            }}
                                            {...row.getRowProps()}
                                            key={rowIndex}
                                            className={classNames(
                                                props.showSidePanel ? style.tableRow : "",
                                                props.scrollTo !== undefined && rowIndex === props.scrollTo - 1
                                                    ? style.rowBeforeScrollTo
                                                    : "",
                                                {
                                                    [style.lastSubRow]: isLastSubRow(row.id),
                                                    [style.onlyRow]: isOnlyRow(),
                                                }
                                            )}
                                            ref={rowIndex === props.scrollTo ? scrollRef : null}
                                            data-depth={row.depth}
                                        >
                                            {row.cells.map((cell, cellIndex) => {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={classNames({
                                                            [style.fixedColumn]: cell.column.fixedToLeft,
                                                            [style.fixedToRight]: cell.column.fixedToRight,
                                                            [style.fixedToLeft]: cell.column.fixedToLeft,
                                                        })}
                                                        key={cellIndex}
                                                    >
                                                        <span>
                                                            {cell.render("Cell", {
                                                                isLoading: Object.keys(row.original).length === 0,
                                                            })}
                                                        </span>
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            })}
                    </tbody>
                </table>
            </div>
        </>
    );
}

export default connector(Table);
