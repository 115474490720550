import * as React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";

import style from "components/home/bde-home-page/getting-started.scss";
import CircleNumber from "components/home/CircleWithNumber";
import { GettingStartedLayout } from "components/home/GettingStartedLayout";
import ExpandedStepIcon from "components/icons/ExpandedStepIcon";
import InactiveIcon from "components/icons/InactiveIcon";
import StepCompletedIcon from "components/icons/StepCompletedIcon";
import { LicenseData } from "domain/licenses";
import { OnboardingProductType } from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { StoreState } from "store";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

interface Props {
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    fetchedSoftwarePackages?: SoftwarePackage[];
    from?: string;
    saveBmdeOnboarded: (onboarded: boolean) => void;
}

const connector = connect(mapState);

export const GettingStarted = (props: Props): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <GettingStartedLayout
                product={OnboardingProductType.BMDE}
                visible={true}
                footerVisibility={false}
                extraHeaderText={t("Onboarding.bmde.productTitle")}
                title={t("Onboarding.gettingStartedFlow.title")}
                description={t("Onboarding.bmde.gettingStarted.description")}
                licenses={props.licenses}
                fetchedLicenses={props.fetchedLicenses}
                fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                fromPage={props.from}
                saveBmdeOnboarded={props.saveBmdeOnboarded}
            />
        </>
    );
};

export default connector(GettingStarted);

export function displayStepCompletionIcon(
    menuExpanded: boolean,
    stepCompleted: boolean,
    step: number,
    stepActive: boolean,
    stepCompletionColor: string,
    whiteColor: string,
    expandedIconBackgroundColor: string,
    inactiveIconColor: string
) {
    return stepCompleted ? (
        <StepCompletedIcon color={stepCompletionColor} checkMarkColor={whiteColor} />
    ) : menuExpanded ? (
        <ExpandedStepIcon color={expandedIconBackgroundColor} />
    ) : stepActive ? (
        <CircleNumber number={step} subStep={false} />
    ) : (
        <InactiveIcon color={inactiveIconColor} step={step} />
    );
}

export function displaySubStepsWithLabel(instruction: string, number: number) {
    return (
        <div className={style.subSteps}>
            <div className={style.stepContainer}>
                <CircleNumber number={number} subStep={true} />
                {instruction}
            </div>
        </div>
    );
}
