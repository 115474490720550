import classNames from "classnames";
import { useFeature } from "flagged";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import ipaddr from "ipaddr.js";
import * as React from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "react-toggle/style.css";
import { object, string } from "yup";

import style from "./settings-view.scss";
import Button from "components/button/Button";
import CopyToClipboard from "components/copy-to-clipboard/CopyToClipboard";
import AddNewIcon from "components/icons/AddNewIcon";
import Delete from "components/icons/Delete";
import Export from "components/icons/Export";
import Info from "components/icons/Info";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import loginStyle from "components/login/login.scss";
import Modal from "components/modal/Modal";
import {
    DASHBOARD_ROUTE,
    DELIVERYHISTORY_LICENSES_ROUTE,
    HOME_ROUTE,
    LICENSES_ROUTE,
    REPORTS_ROUTE,
    RouteDefinition,
    SUPPORT_AND_HELP_ROUTE,
    SUSTAINABILITY_DASHBOARD_ROUTE,
    TENANTS_ROUTE,
    USERS_ROUTE,
    WORKFLOWS_ROUTE,
} from "components/router/Routes";
import ImportLogo from "components/settings/import-logo/ImportLogo";
import { TenantDetails } from "components/settings/tenant-details/TenantDetails";
import TextWithTooltip from "components/table/TextWithTooltip";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import {
    AUTH_LICENSE_ASSIGN,
    AUTH_LICENSE_VIEW,
    AUTH_SSO_CONFIGURE,
    AUTH_TENANT_ACCESS,
    AUTH_TENANT_SETTINGS,
} from "domain/authority";
import { LoginMethod, loginMethodList, Tenant } from "domain/tenants";
import { UserDetails } from "domain/user";
import { SsoConfiguration } from "domain/users";
import { FLAG_ONBOARDING } from "services/feature/FeatureFlagService";
import * as settingRepository from "services/settings/settingsRepository";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import {
    getCurrentTenantDetails,
    getTenantLoginMethod,
    getTenantUuid,
    hasTenantCookie,
} from "services/tenants/tenantCookieService";
import { tenantService } from "services/tenants/TenantService";
import { userService } from "services/user/users/UserService";
import { userSessionService } from "services/user/UserSessionService";
import { StoreState } from "store";
import { updateTenantDetails } from "store/tenantDetails";
import { applyMode } from "store/theme";
import { setUser } from "store/user";
import buttonStyle from "styles/buttons.scss";
import form from "styles/form.scss";
import { isStringBlank } from "utils/commonFunctions";
import { createDateLabel } from "utils/format";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    themeName: state.themeReducer.themeName,
    theme: state.themeReducer.theme,
    user: state.userReducer.user,
    tenantDetails: state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1],
});

export interface FormValues {
    statistics: boolean;
    loginMethod: LoginMethod;
}

export interface SsoFormValues {
    protocol: string;
    metadataUrl: string;
    clientId: string;
    clientSecret: string;
    oidcIssuer: string;
}

interface Result {
    title: string;
    message: string;
}

enum DialogState {
    LOADING = 0,
    LOADING_SUCCESS,
    LOADING_FAILED,
}

interface Props {
    onCancel: () => void;
}

interface CidrState {
    inputs: string[];
    errors: { [key: number]: string };
    changed: boolean;
}

enum Protocol {
    SAML = "SAML",
    OIDC = "OIDC",
}

const connector = connect(mapState, { applyMode, setUser, updateTenantDetails });
const SettingsView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    if (props.user == null) {
        return <LoadingIndicator />;
    }
    const { t } = useTranslation();
    const toggleStatisticsDisabled = props.user.tenantType === "INTERNAL";
    const DEFAULT_LOGIN_METHOD = "COMPANY_ID_AND_EMAIL";
    const CURRENT_TENANT_LOGIN_METHOD = getTenantLoginMethod();
    const [statisticsEnabled, setStatisticsEnabled] = React.useState<boolean>(
        settingRepository.getDataCollection() ?? props.user.usageStatistics
    );
    const [defaultView, setDefaultView] = React.useState<string>(
        props.user.default_views ? props.user.default_views.default_view : HOME_ROUTE.path
    );
    const [tenantAccessDefaultView, setTenantAccessDefaultView] = React.useState<string>(
        props.user.default_views
            ? props.user.default_views.tenant_access_default_view
            : userSessionService.userHasAllAuthorities([AUTH_LICENSE_VIEW, AUTH_LICENSE_ASSIGN])
            ? DELIVERYHISTORY_LICENSES_ROUTE.path
            : HOME_ROUTE.path
    );
    const [loading, setLoading] = React.useState<boolean>(false);
    const [changed, setChanged] = React.useState<boolean>(false);
    const [showModal, setShowModal] = React.useState<boolean>(false);
    const [result, setResult] = React.useState<Result>({ title: "", message: "" });
    const [resultVisible, setResultVisible] = React.useState(false);
    const [deleteResultVisible, setDeleteResultVisible] = React.useState<boolean>(false);
    const [deleteSuccessful, setDeleteSuccessful] = React.useState<boolean>(false);
    const [saveResultVisible, setSaveResultVisible] = React.useState<boolean>(false);

    const [uploadLogoFileList] = React.useState<FileList | undefined>(undefined);
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const iframeRef = React.useRef<HTMLIFrameElement>(null);
    const [tenantDetails, setTenantDetails] = React.useState<Tenant>();
    const [tenantDialogState, setTenantDialogState] = React.useState(DialogState.LOADING);
    const [loadingSsoTab, setLoadingSsoTab] = React.useState<boolean>(false);
    const [ssoLoginDetails, setSsoLoginDetails] = React.useState<SsoConfiguration>();
    const [isShowIdpDetails, setIsShowIdpDetails] = React.useState(true);
    const [isShowConfigurationsDetails, setIsShowConfigurationsDetails] = React.useState(false);
    const [isShowSamlFields, setIsShowSamlFields] = React.useState(true);
    const [isShowOidcFields, setIsShowOidcFields] = React.useState(false);
    const [selectedProtocol, setSelectedProtocol] = React.useState(Protocol.SAML);
    const tenantDetail = useSelector(
        (state: StoreState) => state.tenantDetailsReducer.stack[state.tenantDetailsReducer.stack.length - 1]
    );
    const [loginMethod, setLoginMethod] = React.useState<LoginMethod | undefined>(
        isStringBlank(CURRENT_TENANT_LOGIN_METHOD) ? DEFAULT_LOGIN_METHOD : CURRENT_TENANT_LOGIN_METHOD
    );
    const [loginMethodChanged, setLoginMethodChanged] = React.useState<boolean>(false);
    const LOGIN_METHODS_TO_TRANSLATION_MAP = new Map<LoginMethod, string>([
        ["COMPANY_ID", t("Settings.loginSettings.loginMethod.companyId")],
        ["EMAIL", t("Settings.loginSettings.loginMethod.emailSignIn")],
        ["COMPANY_ID_AND_EMAIL", t("Settings.loginSettings.loginMethod.both")],
    ]);
    const [ssoConfigurationFound, setSsoConfigurationFound] = React.useState(false);
    const MAX_CIDR_BLOCK_FIELDS = 10;
    const [cidrState, setCidrState] = React.useState<CidrState>({
        inputs: [],
        errors: {},
        changed: false,
    });
    const [originalCidrInputs, setOriginalCidrInputs] = React.useState<string[]>([]);

    const tenantUuid = hasTenantCookie() ? getTenantUuid() : props.user.tenantUuid;
    useEffect(() => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        tenantService
            .fetchTenant(tenantUuid, abortController)
            .then((tenantDetails) => {
                setTenantDetails(tenantDetails);
                setLoginMethod(tenantDetails.loginMethod);
                setTenantDialogState(DialogState.LOADING_SUCCESS);
                setCidrState({
                    errors: {},
                    inputs: tenantDetails.ipRestrictions,
                    changed: false,
                });
                setOriginalCidrInputs(tenantDetails.ipRestrictions);
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setTenantDialogState(DialogState.LOADING_FAILED);
                }
            });
        if (props.tenantDetails.uuid) {
            ssoConfigurationFetchHandler();
        }
    }, []);

    const submitHandler: FormikConfig<FormValues>["onSubmit"] = async (_, { setSubmitting }) => {
        if (props.user == null) {
            throw new Error("User is not set");
        }
        setSubmitting(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        const updatedStatistics = changed ? statisticsEnabled : undefined;
        const updatedLoginMethod = loginMethodChanged ? loginMethod : undefined;
        const tenantUuid = hasTenantCookie() ? getTenantUuid() : props.user.tenantUuid;
        const ipRestrictions = cidrState.inputs.filter((each) => each !== "");

        try {
            await tenantService.updateTenantSettings(
                tenantUuid,
                updatedStatistics,
                updatedLoginMethod,
                ipRestrictions,
                abortController
            );
            if (changed) {
                settingRepository.setDataCollection(statisticsEnabled);
            }
            if (loginMethodChanged && typeof loginMethod !== "undefined") {
                const tenantDetails = getCurrentTenantDetails();
                tenantDetails.loginMethod = loginMethod;
                props.updateTenantDetails(tenantDetails);
            }
            setChanged(false);
            setLoginMethodChanged(false);
            showResult({
                title: t("Settings.successTitle"),
                message: t("Settings.successMessage"),
            });
        } catch (e) {
            if (!signal.aborted) {
                showResult({
                    title: t("Settings.errorTitle"),
                    message: t("Settings.errorMessage"),
                });
            }
        }
        setSubmitting(false);
    };
    const ssoSubmitHandler: FormikConfig<SsoFormValues>["onSubmit"] = async (values, { setSubmitting }) => {
        setSubmitting(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            await userService.createSsoConfiguration(
                props.tenantDetails.uuid,
                values.protocol,
                values.metadataUrl,
                values.clientId,
                values.clientSecret,
                values.oidcIssuer,
                abortController
            );
            setChanged(false);
            setLoginMethodChanged(false);
            showSsoResult({
                title: t("Settings.ssoSuccessTitle"),
                message: t("Settings.ssoSaveMessage"),
            });
            setIsShowConfigurationsDetails(true);
            setIsShowIdpDetails(false);
        } catch (e) {
            if (!signal.aborted) {
                showSsoResult({
                    title: t("Settings.ssoErrorTitle"),
                    message: t("Settings.ssoSaveErrorMessage"),
                });
            }
        }

        if (props.tenantDetails.uuid) {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            setLoadingSsoTab(true);
            userService
                .fetchSsoSettings(props.tenantDetails.uuid, values.protocol, abortController)
                .then((ssoLoginDetails) => {
                    setSsoLoginDetails(ssoLoginDetails);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        showResult({
                            title: t("Settings.ssoErrorTitle"),
                            message: t("Settings.ssoFetchErrorMessage"),
                        });
                    }
                })
                .finally(() => {
                    setLoadingSsoTab(false);
                });
            setSsoLoginDetails(ssoLoginDetails);
        }
        setSubmitting(false);
    };

    React.useEffect(() => {
        return () => {
            abortControllers.forEach((abortController) => abortController.abort());
        };
    }, []);

    const toggleStatistics = () => {
        setStatisticsEnabled(!statisticsEnabled);
        setChanged(!changed);
    };

    const onOpenModal = () => {
        usageStatisticsService.sendEvent({
            category: Category.HEADER,
            action: Action.VIEW_TERMS_AND_CONDITIONS,
        });
        setShowModal(true);
    };

    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setResultVisible(true);
    };

    const showSsoResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setSaveResultVisible(true);
    };

    const hideSaveResult = () => {
        setSaveResultVisible(false);
    };

    const hideResult = () => {
        props.onCancel();
    };

    const createDefaultViewSelection = (additionalRoutes: RouteDefinition[]): JSX.Element[] => {
        const routes = [
            DASHBOARD_ROUTE,
            REPORTS_ROUTE,
            SUSTAINABILITY_DASHBOARD_ROUTE,
            LICENSES_ROUTE,
            DELIVERYHISTORY_LICENSES_ROUTE,
            USERS_ROUTE,
            WORKFLOWS_ROUTE,
            SUPPORT_AND_HELP_ROUTE,
            ...additionalRoutes,
        ];
        if (useFeature(FLAG_ONBOARDING)) {
            routes.push(HOME_ROUTE);
        }

        routes.sort((routeA, routeB) => t(routeA.title).localeCompare(t(routeB.title)));

        const options: JSX.Element[] = [];
        routes.forEach((route, index) => {
            route.isValidUser(props.user) &&
                route.isAccessibleTo(props.tenantDetails) &&
                options.push(
                    <option key={index} value={route.path}>
                        {t(route.title)}
                    </option>
                );
        });
        return options;
    };

    const isDisabled = (): boolean => {
        let disabled = true;
        if (props.user?.default_views) {
            if (
                defaultView != props.user?.default_views.default_view ||
                (props.user?.tenantType === "INTERNAL" &&
                    tenantAccessDefaultView != props.user.default_views.tenant_access_default_view)
            ) {
                disabled = true;
            }
        } else {
            if (
                defaultView != SUPPORT_AND_HELP_ROUTE.path ||
                (props.user?.tenantType === "INTERNAL" && tenantAccessDefaultView != SUPPORT_AND_HELP_ROUTE.path)
            ) {
                disabled = true;
            }
        }
        return disabled;
    };

    const changeDefaultViewValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setDefaultView(e.target.value);
    };

    const changeTenantAccessDefaultViewValue = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setTenantAccessDefaultView(e.target.value);
    };

    const changeProtocolHandler = async () => {
        setResultVisible(true);
    };

    const showDeleteResult = (resultToShow: Result) => {
        setResult(resultToShow);
        setDeleteResultVisible(true);
        setResultVisible(false);
    };
    const hideDeleteResult = () => {
        if (deleteSuccessful) {
            setIsShowConfigurationsDetails(false);
            setIsShowIdpDetails(true);
        }
        setDeleteResultVisible(false);
    };

    const deleteConfigurationHandler = async () => {
        setLoading(true);
        if (props.tenantDetails.uuid) {
            const abortController = new AbortController();
            abortControllers.push(abortController);

            await userService
                .deleteSsoSettings(props.tenantDetails.uuid, abortController)
                .then(() => {
                    showDeleteResult({
                        title: t("Settings.ssoSuccessTitle"),
                        message: t("Settings.ssoDeleteSuccessMessage"),
                    });
                    setLoading(false);
                    setDeleteSuccessful(true);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        showDeleteResult({
                            title: t("Settings.ssoErrorTitle"),
                            message: t("Settings.ssoDeleteErrorMessage"),
                        });
                        setDeleteSuccessful(false);
                        setLoading(false);
                    }
                });
        }
    };

    const cancelHandler = async () => {
        setResultVisible(false);
    };

    const handleProtocolChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoadingSsoTab(true);
        setSelectedProtocol(e.target.value as Protocol);
        if (e.target.value === Protocol.OIDC) {
            setIsShowOidcFields(true);
            setIsShowSamlFields(false);
        } else {
            setIsShowOidcFields(false);
            setIsShowSamlFields(true);
        }
        if (props.tenantDetails.uuid) {
            const abortController = new AbortController();
            abortControllers.push(abortController);
            userService
                .fetchSsoSettings(props.tenantDetails.uuid, e.target.value, abortController)
                .then((ssoLoginDetails) => {
                    setSsoLoginDetails(ssoLoginDetails);
                    setLoadingSsoTab(false);
                })
                .catch(() => {
                    if (!abortController.signal.aborted) {
                        setLoadingSsoTab(false);
                    }
                });
        }
    };

    const defaultViewsSubmitHandler = async () => {
        if (props.user == null) {
            throw new Error("User is not set");
        }
        setLoading(true);
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        try {
            const defaultViews = {
                default_view: defaultView,
                tenant_access_default_view:
                    userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                    userSessionService.userHasAllAuthorities([AUTH_TENANT_ACCESS])
                        ? tenantAccessDefaultView
                        : "",
            };
            await userService
                .editDefaultViewSettings(props.user.uuid, defaultViews, abortController)
                .then(() => {
                    if (props.user) {
                        const userDetails: UserDetails = { ...props.user, default_views: defaultViews };
                        userSessionService.storeUser(userDetails);
                        props.setUser(userDetails);
                    }

                    showResult({
                        title: t("Settings.successTitle"),
                        message: t("Settings.successMessage"),
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
        } catch (e) {
            if (!signal.aborted) {
                setLoading(false);
                showResult({
                    title: t("Settings.errorTitle"),
                    message: t("Settings.errorMessage"),
                });
            }
        }
    };
    const ssoConfigurationFetchHandler = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        setLoadingSsoTab(true);
        setLoading(true);
        userService
            .fetchSsoSettings(props.tenantDetails.uuid, undefined, abortController)
            .then((ssoLoginDetails) => {
                setSsoLoginDetails(ssoLoginDetails);
                if (ssoLoginDetails != null) {
                    setIsShowConfigurationsDetails(true);
                    setIsShowIdpDetails(false);
                    setSsoConfigurationFound(true);
                }
            })
            .catch(() => {
                // A failure under normal circumstances means that customer had no SSO data. Fetch again, this time
                // with protocol set to get SSO url and entity ID.
                userService
                    .fetchSsoSettings(props.tenantDetails.uuid, Protocol.SAML, abortController)
                    .then((ssoLoginDetails) => {
                        setSsoLoginDetails(ssoLoginDetails);
                        setSsoConfigurationFound(false);
                    })
                    .catch(() => {
                        if (!abortController.signal.aborted) {
                            console.error("Failed to fetch SSO settings.");
                        }
                    });
            })
            .finally(() => {
                setLoadingSsoTab(false);
                setLoading(false);
            });
        setSsoLoginDetails(ssoLoginDetails);
    };

    const isValidCidr = (cidr: string): boolean => {
        try {
            if (!cidr.includes("/")) {
                // If there's no '/', treat it as an IP address
                return ipaddr.isValid(cidr);
            }

            const [ip, prefix] = cidr.split("/");

            if (!ipaddr.isValid(ip)) {
                return false; // Invalid IP address
            }

            const prefixNum = parseInt(prefix, 10);
            if (isNaN(prefixNum)) {
                return false; // Prefix is not a number
            }

            const maxPrefix = ipaddr.parse(ip).kind() === "ipv4" ? 32 : 128;
            return prefixNum >= 0 && prefixNum <= maxPrefix;
        } catch (error) {
            return false;
        }
    };

    const handleCidrChange = (index: number, value: string) => {
        setCidrState((prevState) => {
            const newInputs = [...prevState.inputs];
            newInputs[index] = value;

            const newErrors = { ...prevState.errors };
            let allValid = true;
            let hasDuplicate = false;
            newInputs.forEach((input, i) => {
                if (input !== "" && !isValidCidr(input)) {
                    newErrors[i] = t("Settings.cidrConfiguration.ipValidationError");
                    allValid = false;
                } else {
                    delete newErrors[i];
                }
                if (newInputs.indexOf(input) !== i && input !== "") {
                    newErrors[i] = t("Settings.cidrConfiguration.duplicateCidrError");
                    hasDuplicate = true;
                }
            });
            const changed = allValid && !hasDuplicate && !newInputs.some((input) => input === "");

            return {
                inputs: newInputs,
                errors: newErrors,
                changed, // Only true if all inputs are valid and there no any duplicates.
            };
        });
    };

    const handleAddCidr = () => {
        setCidrState((prevState) => {
            if (prevState.inputs.length >= MAX_CIDR_BLOCK_FIELDS) {
                return prevState;
            }
            return {
                ...prevState,
                inputs: [...prevState.inputs, ""],
                changed: false,
            };
        });
    };

    const handleRemoveCidr = (index: number) => {
        setCidrState((prevState) => {
            const newInputs = prevState.inputs.filter((_, i) => i !== index);
            const newErrors = { ...prevState.errors };
            delete newErrors[index];
            const allCidrsValid = newInputs.every((input) => isValidCidr(input));
            return {
                inputs: newInputs,
                errors: newErrors,
                changed:
                    allCidrsValid && !hasEmptyInput(newInputs)
                        ? true
                        : originalCidrInputs.length !== 0 && !hasEmptyInput(newInputs),
            };
        });
    };
    const hasEmptyInput = (inputs: string[]) => {
        return inputs.some((input) => input.trim() === "");
    };

    const userDetailModal = loading ? (
        <div className={style.loadingIndicator}>
            <LoadingIndicator />
        </div>
    ) : (
        <div>
            <div>
                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                    {t("Settings.termsAndConditionsTitle")}
                </Heading>
                <div className={style.popUp}>
                    {t("Settings.termsAndConditions")}
                    <div className={style.termsDialog}>
                        <div data-testid={testIds.navigation.settingsDialog.tos.acceptedDate}>
                            {t("Settings.acceptedDate")}
                            {props.user.eulaAcceptedDate ? createDateLabel(props.user.eulaAcceptedDate) : ""}
                        </div>
                        <button
                            className={classNames(style.link, buttonStyle.textButton)}
                            onClick={onOpenModal}
                            data-testid={testIds.navigation.settingsDialog.tos.termsAndConditionsLink}
                        >
                            {t("Settings.viewTermsAndConditions")}
                        </button>
                        <Modal
                            isOpen={showModal}
                            hideModal={() => setShowModal(false)}
                            modalTitle={t("Login.acceptEulaModal.title")}
                        >
                            <div className={classNames(loginStyle.settingViewEulaModalWrapper)}>
                                <div className={loginStyle.eulaActionsContainer}>
                                    <Export color={props.theme.linkTextColor} />
                                    <a
                                        href="/public/eula/eula.pdf"
                                        target="_blank"
                                        data-testid={testIds.navigation.settingsDialog.tos.downloadPdfLink}
                                        onClick={() => {
                                            usageStatisticsService.sendEvent({
                                                category: Category.HEADER,
                                                action: Action.DOWNLOAD_TERMS_AND_CONDITIONS,
                                            });
                                        }}
                                    >
                                        {t("Login.acceptEulaModal.downloadPdf")}
                                    </a>
                                </div>
                                <div className={classNames(loginStyle.eulaDivContainer, style.eulaBottom)}>
                                    <iframe
                                        ref={iframeRef}
                                        src="/public/eula/eula.html"
                                        scrolling="yes"
                                        className={loginStyle.eulaContainer}
                                        data-testid={testIds.login.eula.contentIframe}
                                    />
                                </div>
                            </div>
                        </Modal>
                    </div>
                </div>
            </div>

            <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                {t("Settings.defaultSettings")}
            </Heading>
            <div className={style.formField}>
                <label className={form.label}>{t("Settings.defaultView")}</label>
                <select
                    name="default_view"
                    className={classNames(form.select, form.fixedWidthInput)}
                    onChange={changeDefaultViewValue}
                    value={defaultView}
                    data-testid={testIds.navigation.settingsDialog.defaultViews.defaultViewSelect}
                    defaultValue={defaultView}
                >
                    <option value="">{t("Settings.selectDefaultView")}</option>
                    {createDefaultViewSelection([TENANTS_ROUTE])}
                </select>
            </div>
            {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                userSessionService.userHasAllAuthorities([AUTH_TENANT_ACCESS]) && (
                    <div className={style.formField}>
                        <label className={form.label}>{t("Settings.customerAccessDefaultView")}</label>
                        <select
                            name="tenant_access_default_view"
                            className={classNames(form.select, form.fixedWidthInput)}
                            onChange={changeTenantAccessDefaultViewValue}
                            value={tenantAccessDefaultView}
                            data-testid={testIds.navigation.settingsDialog.defaultViews.tenantAccessDefaultViewSelect}
                            defaultValue={tenantAccessDefaultView}
                        >
                            <option value="">{t("Settings.selectDefaultView")}</option>
                            {createDefaultViewSelection([])}
                        </select>
                    </div>
                )}
            <div className={form.buttonContainer}>
                <button
                    type="submit"
                    className={buttonStyle.primaryButtonWithoutIcon}
                    data-testid={testIds.navigation.settingsDialog.submitButton}
                    disabled={hasTenantCookie() || !isDisabled()}
                    onClick={defaultViewsSubmitHandler}
                >
                    {t("Common.save")}
                </button>
            </div>

            <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                <div className={style.resultContainer}>{result.message}</div>
                <div className={style.okButtonContainer}>
                    <button
                        className={buttonStyle.primaryOkButton}
                        onClick={hideResult}
                        data-testid={testIds.common.dialog.closeButton}
                    >
                        {t("Common.ok")}
                    </button>
                </div>
            </Modal>
        </div>
    );

    const weblink = "https://support.blancco.com/x/UIDZBg";

    let tenantDetailModal = <></>;
    switch (tenantDialogState) {
        case DialogState.LOADING:
            tenantDetailModal = <LoadingIndicator />;
            break;
        case DialogState.LOADING_SUCCESS:
            tenantDetailModal = <TenantDetails activeTenant={tenantDetails} />;
            break;
        case DialogState.LOADING_FAILED:
            tenantDetailModal = (
                <div>
                    <div className={style.popUp}>{t("CustomerProfile.refreshRequestFailed")}</div>
                    <div className={form.buttonContainer}>
                        <button
                            className={buttonStyle.primaryOkButton}
                            onClick={hideResult}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("Common.ok")}
                        </button>
                    </div>
                </div>
            );
            break;
    }

    return loading ? (
        <LoadingIndicator />
    ) : (
        <div className={style.tabs}>
            <Tabs>
                <TabList>
                    <Tab>{t("Settings.mySettings")}</Tab>
                    <Tab>{t("Settings.sharedSettings")}</Tab>

                    {tenantDetail.featureLicenses.includes("FEATURE_SSO") &&
                        userSessionService.userHasAllAuthorities([AUTH_SSO_CONFIGURE]) && (
                            <Tab>{t("Settings.ssoSettings")}</Tab>
                        )}
                </TabList>
                <TabPanel>{hasTenantCookie() ? tenantDetailModal : userDetailModal}</TabPanel>
                <TabPanel>
                    <Formik
                        initialValues={{
                            statistics: statisticsEnabled,
                            loginMethod: loginMethod,
                        }}
                        onSubmit={submitHandler}
                    >
                        {({ isSubmitting }: FormikProps<FormValues>) => {
                            if (isSubmitting || props.user == null) {
                                return <LoadingIndicator />;
                            }
                            return (
                                <div className={style.successBody}>
                                    <Form>
                                        {
                                            <div>
                                                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                    {t("Settings.statisticsTitle")}
                                                </Heading>
                                                {userSessionService.hasFeatureLicense("FEATURE_TENANT_MANAGEMENT") &&
                                                    userSessionService.userHasAllAuthorities([AUTH_TENANT_SETTINGS]) &&
                                                    !hasTenantCookie() && (
                                                        <div className={style.popUp}>
                                                            <div className={style.statisticsLabel}>
                                                                {t("Settings.gatherStatistics")}
                                                            </div>
                                                            <div className={style.checkbox}>
                                                                <label className={form.container}>
                                                                    <input
                                                                        type="checkbox"
                                                                        name="statistics"
                                                                        checked={statisticsEnabled}
                                                                        onClick={toggleStatistics}
                                                                        disabled={toggleStatisticsDisabled}
                                                                        data-testid={
                                                                            testIds.navigation.settingsDialog.statistics
                                                                                .collectCheckbox
                                                                        }
                                                                    />
                                                                    <span className={form.checkmark} />
                                                                </label>
                                                            </div>
                                                            <div className={style.warningMessage}>
                                                                <Tooltip
                                                                    content={<>{t("Settings.warningTooltip")}</>}
                                                                    placement={"top"}
                                                                >
                                                                    <div className={form.infoIcon} tabIndex={0}>
                                                                        <Info
                                                                            borderColor={
                                                                                props.theme.contentBackgroundColor
                                                                            }
                                                                            color={props.theme.iconFillColor}
                                                                        />
                                                                    </div>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    )}
                                                <div className={style.warningText}>
                                                    <div
                                                        data-testid={testIds.navigation.settingsDialog.tos.acceptedDate}
                                                    >
                                                        {t("Settings.warning")}
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {tenantDetail.featureLicenses.includes("FEATURE_SSO") &&
                                            userSessionService.userHasAllAuthorities([
                                                AUTH_TENANT_SETTINGS,
                                                AUTH_SSO_CONFIGURE,
                                            ]) &&
                                            ssoConfigurationFound && (
                                                <div className={style.sectionsSpacing}>
                                                    <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                        {t("Settings.loginSettings.title")}
                                                    </Heading>
                                                    <div className={style.warningText}>
                                                        <div>{t("Settings.loginSettings.effect")}</div>
                                                    </div>
                                                    <div className={form.formFields}>
                                                        <label htmlFor="loginMethod" className={form.label}>
                                                            {t("Settings.loginSettings.loginMethod.label")}
                                                        </label>
                                                        <select
                                                            id="loginMethod"
                                                            className={classNames(form.select, form.fixedWidthInput)}
                                                            onChange={(e) => {
                                                                const selectedValue = e.target.value;
                                                                setLoginMethod(selectedValue);

                                                                setLoginMethodChanged(
                                                                    CURRENT_TENANT_LOGIN_METHOD !== selectedValue
                                                                );
                                                            }}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.loginMethodSelect
                                                            }
                                                            value={
                                                                isStringBlank(loginMethod)
                                                                    ? DEFAULT_LOGIN_METHOD
                                                                    : loginMethod
                                                            }
                                                        >
                                                            {loginMethodList.map((each) => (
                                                                <option key={each} value={each}>
                                                                    {LOGIN_METHODS_TO_TRANSLATION_MAP.get(each)}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            )}
                                        {tenantDetail.featureLicenses.includes("FEATURE_PUBLIC_APIS") && (
                                            <>
                                                <div className={style.sectionsSpacing}>
                                                    <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                        {t("Settings.cidrConfiguration.title")}
                                                    </Heading>

                                                    <div className={style.warningText}>
                                                        {t("Settings.cidrConfiguration.note")}
                                                    </div>

                                                    <div className={style.inputScrollBar}>
                                                        {cidrState.inputs.map((cidr, index) => (
                                                            <div key={index}>
                                                                <div className={style.cdirConfigDiv}>
                                                                    <input
                                                                        value={cidr}
                                                                        className={classNames(
                                                                            form.input,
                                                                            style.cdirConfigInput,
                                                                            form.fixedWidthInput,
                                                                            {
                                                                                [form.inputError]:
                                                                                    cidrState.errors[index],
                                                                            }
                                                                        )}
                                                                        onChange={(e) =>
                                                                            handleCidrChange(index, e.target.value)
                                                                        }
                                                                    />
                                                                    <span
                                                                        onClick={() => handleRemoveCidr(index)}
                                                                        className={classNames(style.actionIcon)}
                                                                        data-testid={
                                                                            testIds.navigation.settingsDialog.cidr
                                                                                .cidrBlockInput.deleteButton
                                                                        }
                                                                    >
                                                                        <Delete
                                                                            strokeColor={props.theme.errorIconColor}
                                                                            height={"35px"}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                <div className={style.inputError}>
                                                                    {cidrState.errors[index] && (
                                                                        <div className={style.error}>
                                                                            <span>{cidrState.errors[index]}</span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    {cidrState.inputs.length >= MAX_CIDR_BLOCK_FIELDS && (
                                                        <div className={style.maxLimitError}>
                                                            {t("Settings.cidrConfiguration.maxLimitMessage", {
                                                                cidrCount: MAX_CIDR_BLOCK_FIELDS,
                                                            })}
                                                        </div>
                                                    )}
                                                    <div className={style.addNewBlock}>
                                                        <Button
                                                            variant={"TERTIARY"}
                                                            customIcon={
                                                                <AddNewIcon
                                                                    className={style.addNewIcon}
                                                                    color={props.theme.iconFillColor}
                                                                />
                                                            }
                                                            onClick={handleAddCidr}
                                                            type="button"
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.cidr.addButton
                                                            }
                                                            disabled={cidrState.inputs.length >= MAX_CIDR_BLOCK_FIELDS}
                                                        >
                                                            {t("Settings.cidrConfiguration.addCidrBlock")}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                        {userSessionService.userHasAllAuthorities([AUTH_TENANT_SETTINGS]) && (
                                            <div className={style.sectionsSpacing}>
                                                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                    {t("Settings.selectFile.title")}
                                                </Heading>
                                                <ImportLogo
                                                    fileList={
                                                        uploadLogoFileList !== undefined
                                                            ? Array.from(uploadLogoFileList)
                                                            : undefined
                                                    }
                                                    tenantService={tenantService}
                                                />
                                            </div>
                                        )}

                                        <div className={form.buttonContainer}>
                                            <button
                                                type="submit"
                                                className={buttonStyle.primaryButtonWithoutIcon}
                                                data-testid={testIds.navigation.settingsDialog.submitButton}
                                                disabled={!(loginMethodChanged || cidrState.changed || changed)}
                                            >
                                                {t("Common.save")}
                                            </button>
                                        </div>
                                    </Form>
                                    <Modal isOpen={resultVisible} hideModal={hideResult} modalTitle={result.title}>
                                        <div className={style.resultContainer}>{result.message}</div>
                                        <div className={style.okButtonContainer}>
                                            <button
                                                className={buttonStyle.primaryOkButton}
                                                onClick={hideResult}
                                                data-testid={testIds.common.dialog.closeButton}
                                            >
                                                {t("Common.ok")}
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            );
                        }}
                    </Formik>
                </TabPanel>
                <TabPanel>
                    <Formik
                        initialValues={{
                            protocol: Protocol.SAML,
                            metadataUrl: "",
                            clientId: "",
                            clientSecret: "",
                            oidcIssuer: "",
                        }}
                        onSubmit={ssoSubmitHandler}
                        validationSchema={object().shape({
                            metadataUrl: string().when("protocol", {
                                is: Protocol.SAML,
                                then: string().required(t("Settings.ssoMetadataUrlValidation")).min(8).max(250).url(),
                            }),

                            clientId: string().when("protocol", {
                                is: Protocol.OIDC,
                                then: string().required(t("Settings.ssoClientIdValidation")).min(1).max(250),
                            }),
                            clientSecret: string().when("protocol", {
                                is: Protocol.OIDC,
                                then: string().required(t("Settings.ssoClientSecretValidation")).min(1).max(250),
                            }),
                            oidcIssuer: string().when("protocol", {
                                is: Protocol.OIDC,
                                then: string().required(t("Settings.ssoOidcIssuerValidation")).min(1).max(250).url(),
                            }),
                        })}
                    >
                        {({ isSubmitting, handleChange, handleBlur }: FormikProps<SsoFormValues>) => {
                            if (isSubmitting || props.user == null || loadingSsoTab) {
                                return <LoadingIndicator />;
                            }
                            return (
                                <div>
                                    <Form>
                                        {isShowIdpDetails && (
                                            <div id="idpSettings" className={style.ssoContainer}>
                                                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                    {t("Settings.ssoConfigurations.idpSettings")}
                                                </Heading>
                                                <div className={style.gridContainer}>
                                                    <div className={style.chooseProtocolDiv}>
                                                        <label className={form.label}>
                                                            {t("Settings.ssoConfigurations.chooseProtocol")}:
                                                        </label>
                                                        <br />
                                                        <input
                                                            type="radio"
                                                            id="samlProtocol"
                                                            value={Protocol.SAML}
                                                            name="protocol"
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.protocolRadio
                                                            }
                                                            checked={selectedProtocol === Protocol.SAML}
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handleProtocolChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <label
                                                            htmlFor="samlProtocol"
                                                            className={style.radioButtonLabel}
                                                        >
                                                            {t("Settings.ssoConfigurations.saml")}
                                                        </label>
                                                        <br />
                                                        <input
                                                            type="radio"
                                                            id="oidcProtocol"
                                                            value={Protocol.OIDC}
                                                            name="protocol"
                                                            checked={selectedProtocol === Protocol.OIDC}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.protocolRadio
                                                            }
                                                            onChange={(e) => {
                                                                handleChange(e);
                                                                handleProtocolChange(e);
                                                            }}
                                                            onBlur={handleBlur}
                                                        />
                                                        <label
                                                            htmlFor="oidcProtocol"
                                                            className={style.radioButtonLabel}
                                                        >
                                                            {t("Settings.ssoConfigurations.oidc")}
                                                        </label>
                                                    </div>
                                                    <div className={style.protocolLinksDiv}>
                                                        {loading ? (
                                                            <div className={style.loadingIndicator}>
                                                                <LoadingIndicator small={true} />
                                                            </div>
                                                        ) : (
                                                            <>
                                                                <div className={style.gridContainer}>
                                                                    <label className={form.label}>
                                                                        {t(
                                                                            "Settings.ssoConfigurations.singleSignOnURL"
                                                                        )}
                                                                        :
                                                                    </label>
                                                                    <label
                                                                        className={classNames(style.settingValueLabel)}
                                                                    >
                                                                        {ssoLoginDetails?.ssoUrl}
                                                                    </label>
                                                                    <div className={style.toolTipDiv}>
                                                                        <CopyToClipboard
                                                                            value={
                                                                                ssoLoginDetails?.ssoUrl != null
                                                                                    ? ssoLoginDetails?.ssoUrl
                                                                                    : " "
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                                {isShowSamlFields && (
                                                                    <div className={style.gridContainer}>
                                                                        <label className={form.label}>
                                                                            {t(
                                                                                "Settings.ssoConfigurations.serviceProviderID"
                                                                            )}
                                                                            :
                                                                        </label>
                                                                        <label className={style.settingValueLabel}>
                                                                            {ssoLoginDetails?.entityId}
                                                                        </label>
                                                                        <div className={style.toolTipDiv}>
                                                                            <CopyToClipboard
                                                                                value={
                                                                                    ssoLoginDetails?.entityId != null
                                                                                        ? ssoLoginDetails?.entityId
                                                                                        : " "
                                                                                }
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className={style.infoAlignment}>
                                                    {t("Settings.ssoConfigurations.message")}
                                                    <a href={weblink} target="_blank" rel="noreferrer noopener">
                                                        {t("Settings.ssoConfigurations.weblink")}
                                                    </a>
                                                </div>
                                                <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                                    {t("Settings.ssoConfigurations.configuration")}
                                                </Heading>
                                                <div className={style.ssoNote}>
                                                    {t("Settings.ssoConfigurations.note")}
                                                </div>
                                                {isShowSamlFields && (
                                                    <div className={style.configurationInputContainer}>
                                                        <div className={style.configurationInputDiv}>
                                                            <label className={form.label}>
                                                                {t("Settings.ssoConfigurations.samlMetadataURL")}:
                                                            </label>

                                                            <input
                                                                type="text"
                                                                name="metadataUrl"
                                                                id="metadataUrl"
                                                                data-testid={
                                                                    testIds.navigation.settingsDialog.sso
                                                                        .metadataUrlInput.itself
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div
                                                            className={style.errorMessage}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.metadataUrlInput
                                                                    .errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="metadataUrl" />
                                                        </div>
                                                    </div>
                                                )}
                                                {isShowOidcFields && (
                                                    <div className={style.configurationInputContainer}>
                                                        <div className={style.configurationInputDiv}>
                                                            <label className={form.label}>
                                                                {t("Settings.ssoConfigurations.clientID")}:
                                                            </label>

                                                            <input
                                                                type="text"
                                                                name="clientId"
                                                                id="clientId"
                                                                data-testid={
                                                                    testIds.navigation.settingsDialog.sso.clientIdInput
                                                                        .itself
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div
                                                            className={style.errorMessage}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.clientIdInput
                                                                    .errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="clientId" />
                                                        </div>
                                                        <div className={style.configurationInputDiv}>
                                                            <label className={form.label}>
                                                                {t("Settings.ssoConfigurations.clientSecret")}:
                                                            </label>

                                                            <input
                                                                type="text"
                                                                name="clientSecret"
                                                                id="clientSecret"
                                                                data-testid={
                                                                    testIds.navigation.settingsDialog.sso
                                                                        .clientSecretInput.itself
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div
                                                            className={style.errorMessage}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.clientSecretInput
                                                                    .errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="clientSecret" />
                                                        </div>
                                                        <div className={style.configurationInputDiv}>
                                                            <label className={form.label}>
                                                                {t("Settings.ssoConfigurations.issuerURL")}:
                                                            </label>

                                                            <input
                                                                type="text"
                                                                name="oidcIssuer"
                                                                id="oidcIssuer"
                                                                data-testid={
                                                                    testIds.navigation.settingsDialog.sso.issuerUrlInput
                                                                        .itself
                                                                }
                                                                onChange={handleChange}
                                                            />
                                                        </div>
                                                        <div
                                                            className={style.errorMessage}
                                                            data-testid={
                                                                testIds.navigation.settingsDialog.sso.issuerUrlInput
                                                                    .errorLabel
                                                            }
                                                        >
                                                            <ErrorMessage name="oidcIssuer" />
                                                        </div>
                                                    </div>
                                                )}
                                                <div className={style.buttonContainer}>
                                                    {
                                                        <button
                                                            type="submit"
                                                            className={buttonStyle.primaryButtonWithoutIcon}
                                                            data-testid={
                                                                testIds.common.confirmationDialog.confirmButton
                                                            }
                                                        >
                                                            {t("Common.save")}
                                                        </button>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                    </Form>
                                    <Modal isOpen={saveResultVisible} hideModal={hideResult} modalTitle={result.title}>
                                        <div className={style.resultContainer}>{result.message}</div>
                                        <div className={style.okButtonContainer}>
                                            <button
                                                className={buttonStyle.primaryOkButton}
                                                onClick={hideSaveResult}
                                                data-testid={testIds.common.dialog.closeButton}
                                            >
                                                {t("Common.ok")}
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            );
                        }}
                    </Formik>
                    {isShowConfigurationsDetails && !loadingSsoTab && (
                        <div className={style.ssoContainer}>
                            <Heading tag="div" variant="SUBTITLE_1" disableTopSpacing={true}>
                                {t("Settings.ssoConfigurations.title")}
                            </Heading>
                            {ssoLoginDetails?.loginUrl != null ? (
                                <div>
                                    <div className={style.gridContainer}>
                                        <label className={form.label}>
                                            {t("Settings.ssoConfigurations.protocol")}:
                                        </label>
                                        <label className={style.settingValueLabel}>{ssoLoginDetails?.protocol}</label>
                                    </div>
                                    <div className={style.gridContainer}>
                                        <label className={form.label}>
                                            {t("Settings.ssoConfigurations.singleSignOnURL")}:
                                        </label>
                                        <label className={style.settingValueLabel}>{ssoLoginDetails?.ssoUrl}</label>
                                        <div className={style.toolTipDiv}>
                                            <CopyToClipboard value={ssoLoginDetails?.ssoUrl} />
                                        </div>
                                    </div>
                                    {ssoLoginDetails?.protocol == Protocol.SAML ? (
                                        <>
                                            <div className={style.gridContainer}>
                                                <label className={form.label}>
                                                    {t("Settings.ssoConfigurations.entityID")}:
                                                </label>
                                                <label className={style.settingValueLabel}>
                                                    {ssoLoginDetails?.entityId}
                                                </label>
                                                <div className={style.toolTipDiv}>
                                                    <CopyToClipboard value={ssoLoginDetails?.entityId} />
                                                </div>
                                            </div>
                                            <div className={style.gridContainer}>
                                                <label className={form.label}>
                                                    {t("Settings.ssoConfigurations.metadataURL")}:
                                                </label>
                                                <label className={classNames(style.settingValueLabel)}>
                                                    {ssoLoginDetails?.metadataUrl}
                                                </label>
                                                <div className={style.toolTipDiv}>
                                                    <CopyToClipboard value={ssoLoginDetails?.metadataUrl} />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <>
                                            <div className={style.gridContainer}>
                                                <label className={form.label}>
                                                    {t("Settings.ssoConfigurations.clientID")}:
                                                </label>
                                                <label className={style.settingValueLabel}>
                                                    {ssoLoginDetails?.clientId}
                                                </label>
                                            </div>
                                            <div className={style.gridContainer}>
                                                <label className={form.label}>
                                                    {t("Settings.ssoConfigurations.issuerURL")}:
                                                </label>
                                                <label className={style.settingValueLabel}>
                                                    {ssoLoginDetails?.oidcIssuer}
                                                </label>
                                                <div className={style.toolTipDiv}>
                                                    <CopyToClipboard value={ssoLoginDetails?.oidcIssuer} />
                                                </div>
                                            </div>
                                        </>
                                    )}
                                    <div className={style.gridContainer}>
                                        <label className={form.label}>{t("Settings.ssoConfigurations.label")}</label>
                                        <div className={style.settingValueLabel}>
                                            <TextWithTooltip text={ssoLoginDetails?.loginUrl} />
                                        </div>
                                        {ssoLoginDetails?.loginUrl != null ? (
                                            <div className={style.toolTipDiv}>
                                                <CopyToClipboard value={ssoLoginDetails?.loginUrl} />
                                                <div className={style.warningMessage}>
                                                    <Tooltip
                                                        content={<>{t("Settings.ssoConfigurations.infoIconMessage")}</>}
                                                        placement={"top"}
                                                    >
                                                        <div className={form.infoIcon} tabIndex={0}>
                                                            <Info
                                                                borderColor={props.theme.contentBackgroundColor}
                                                                color={props.theme.iconFillColor}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                </div>
                                            </div>
                                        ) : (
                                            ""
                                        )}
                                    </div>
                                    <div className={style.infoAlignment}>
                                        {t("Settings.ssoConfigurations.message")}
                                        <a href={weblink} target="_blank" rel="noreferrer noopener">
                                            {t("Settings.ssoConfigurations.weblink")}
                                        </a>
                                    </div>
                                    <div className={style.buttonContainer}>
                                        <button
                                            onClick={changeProtocolHandler}
                                            type="submit"
                                            className={buttonStyle.primaryButtonWithoutIcon}
                                        >
                                            {t("Settings.ssoConfigurations.changeProtocol")}
                                        </button>
                                    </div>
                                    <Modal
                                        isOpen={resultVisible}
                                        hideModal={hideResult}
                                        modalTitle={t("Settings.ssoConfigurations.popUpTitle")}
                                    >
                                        <div className={style.warningPopUp}>
                                            <div className={style.resultContainer}>
                                                {t("Settings.ssoConfigurations.deleteDisclaimer")}
                                                <br />
                                                <br />
                                                {t("Settings.ssoConfigurations.deleteConfirm")}
                                            </div>
                                            {loading ? (
                                                <div className={style.loadingIndicator}>
                                                    <LoadingIndicator />
                                                </div>
                                            ) : (
                                                <div className={style.okButtonContainer}>
                                                    <button
                                                        className={buttonStyle.secondaryButtonWithoutIcon}
                                                        onClick={cancelHandler}
                                                        data-testid={testIds.common.dialog.closeButton}
                                                    >
                                                        {t("Common.cancel")}
                                                    </button>
                                                    <button
                                                        className={classNames(
                                                            buttonStyle.primaryButtonWithoutIcon,
                                                            buttonStyle.deleteButton
                                                        )}
                                                        onClick={deleteConfigurationHandler}
                                                        data-testid={testIds.common.dialog.closeButton}
                                                    >
                                                        {t("Settings.ssoConfigurations.yesDelete")}
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    </Modal>
                                    <Modal
                                        isOpen={deleteResultVisible}
                                        hideModal={hideDeleteResult}
                                        modalTitle={result.title}
                                    >
                                        <div className={style.resultContainer}>{result.message}</div>
                                        <div className={style.okButtonContainer}>
                                            <button
                                                className={buttonStyle.primaryOkButton}
                                                onClick={hideDeleteResult}
                                                data-testid={testIds.common.dialog.closeButton}
                                            >
                                                {t("Common.ok")}
                                            </button>
                                        </div>
                                    </Modal>
                                </div>
                            ) : loading ? (
                                <div className={style.loadingIndicator}>
                                    <LoadingIndicator />
                                </div>
                            ) : (
                                <div className={style.noDataLabel}>
                                    {t("Settings.ssoConfigurations.noLinkAvailable")}
                                </div>
                            )}
                        </div>
                    )}
                </TabPanel>
            </Tabs>
        </div>
    );
};

export default connector(SettingsView);
