import { createSlice } from "@reduxjs/toolkit";

import { ThemeMode } from "domain/themeMode";
import * as settingRepository from "services/settings/settingsRepository";
import { hasSubTenantCookie, hasTenantCookie } from "services/tenants/tenantCookieService";
import defaultColor from "styles/colors/default-color.scss";
import defaultdarkColor from "styles/colors/default-dark-color.scss";
import subtenantColor from "styles/colors/sub-tenant-color.scss";
import subtenantdarkColor from "styles/colors/sub-tenant-dark-color.scss";
import tenantColor from "styles/colors/tenant-color.scss";
import tenantdarkColor from "styles/colors/tenant-dark-color.scss";

export enum ThemeName {
    DEFAULT = "defaultTheme",
    TENANT = "tenantTheme",
    DEFAULT_DARK = "defaultDarkTheme",
    TENANT_DARK = "tenantDarkTheme",
    SUB_TENANT = "subTenantTheme",
    SUB_TENANT_DARK = "subTenantDarkTheme",
}

interface ThemeState {
    theme: typeof defaultColor | typeof tenantColor | typeof subtenantColor;
    themeName: ThemeName;
}

const DEFAULT_STATE: ThemeState = {
    theme: defaultColor,
    themeName: ThemeName.DEFAULT,
};

const DEFAULT_DARK_STATE: ThemeState = {
    theme: defaultdarkColor,
    themeName: ThemeName.DEFAULT_DARK,
};

const TENANT_STATE: ThemeState = {
    theme: tenantColor,
    themeName: ThemeName.TENANT,
};

const TENANT_DARK_STATE: ThemeState = {
    theme: tenantdarkColor,
    themeName: ThemeName.TENANT_DARK,
};

const SUB_TENANT_STATE: ThemeState = {
    theme: subtenantColor,
    themeName: ThemeName.SUB_TENANT,
};

const SUB_TENANT_DARK_STATE: ThemeState = {
    theme: subtenantdarkColor,
    themeName: ThemeName.SUB_TENANT_DARK,
};

const defaultState = () => {
    if (hasTenantCookie() && !hasSubTenantCookie()) {
        return settingRepository.getThemeMode() == ThemeMode.DARK ? TENANT_DARK_STATE : TENANT_STATE;
    } else if (hasSubTenantCookie()) {
        return settingRepository.getThemeMode() == ThemeMode.DARK ? SUB_TENANT_DARK_STATE : SUB_TENANT_STATE;
    } else {
        return settingRepository.getThemeMode() == ThemeMode.DARK ? DEFAULT_DARK_STATE : DEFAULT_STATE;
    }
};

const THEME_SLICE = createSlice({
    name: "themeSlice",

    initialState: defaultState(),

    reducers: {
        switchTheme(state) {
            if (state.themeName === ThemeName.DEFAULT || state.themeName === ThemeName.DEFAULT_DARK) {
                if (hasTenantCookie() && !hasSubTenantCookie) {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = TENANT_DARK_STATE.themeName;
                        state.theme = TENANT_DARK_STATE.theme;
                    } else {
                        state.themeName = TENANT_STATE.themeName;
                        state.theme = TENANT_STATE.theme;
                    }
                } else {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = SUB_TENANT_DARK_STATE.themeName;
                        state.theme = SUB_TENANT_DARK_STATE.theme;
                    } else {
                        state.themeName = SUB_TENANT_STATE.themeName;
                        state.theme = SUB_TENANT_STATE.theme;
                    }
                }
            }
            if (state.themeName === ThemeName.TENANT || state.themeName === ThemeName.TENANT_DARK) {
                if (hasSubTenantCookie()) {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = SUB_TENANT_DARK_STATE.themeName;
                        state.theme = SUB_TENANT_DARK_STATE.theme;
                    } else {
                        state.themeName = SUB_TENANT_STATE.themeName;
                        state.theme = SUB_TENANT_STATE.theme;
                    }
                } else {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = DEFAULT_DARK_STATE.themeName;
                        state.theme = DEFAULT_DARK_STATE.theme;
                    } else {
                        state.themeName = DEFAULT_STATE.themeName;
                        state.theme = DEFAULT_STATE.theme;
                    }
                }
            }
            if (state.themeName === ThemeName.SUB_TENANT || state.themeName === ThemeName.SUB_TENANT_DARK) {
                if (hasSubTenantCookie()) {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = SUB_TENANT_DARK_STATE.themeName;
                        state.theme = SUB_TENANT_DARK_STATE.theme;
                    } else {
                        state.themeName = SUB_TENANT_STATE.themeName;
                        state.theme = SUB_TENANT_STATE.theme;
                    }
                } else if (hasTenantCookie() && !hasSubTenantCookie) {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = TENANT_DARK_STATE.themeName;
                        state.theme = TENANT_DARK_STATE.theme;
                    } else {
                        state.themeName = TENANT_STATE.themeName;
                        state.theme = TENANT_STATE.theme;
                    }
                } else {
                    if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                        state.themeName = DEFAULT_DARK_STATE.themeName;
                        state.theme = DEFAULT_DARK_STATE.theme;
                    } else {
                        state.themeName = DEFAULT_STATE.themeName;
                        state.theme = DEFAULT_STATE.theme;
                    }
                }
            }
        },
        applyMode(state) {
            if (state.themeName === ThemeName.TENANT || state.themeName === ThemeName.TENANT_DARK) {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = TENANT_DARK_STATE.themeName;
                    state.theme = TENANT_DARK_STATE.theme;
                } else {
                    state.themeName = TENANT_STATE.themeName;
                    state.theme = TENANT_STATE.theme;
                }
            } else if (state.themeName === ThemeName.SUB_TENANT || state.themeName === ThemeName.SUB_TENANT_DARK) {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = SUB_TENANT_DARK_STATE.themeName;
                    state.theme = SUB_TENANT_DARK_STATE.theme;
                } else {
                    state.themeName = SUB_TENANT_STATE.themeName;
                    state.theme = SUB_TENANT_STATE.theme;
                }
            } else {
                if (settingRepository.getThemeMode() == ThemeMode.DARK) {
                    state.themeName = DEFAULT_DARK_STATE.themeName;
                    state.theme = DEFAULT_DARK_STATE.theme;
                } else {
                    state.themeName = DEFAULT_STATE.themeName;
                    state.theme = DEFAULT_STATE.theme;
                }
            }
        },
    },
});

export const { switchTheme, applyMode } = THEME_SLICE.actions;

export default THEME_SLICE.reducer;
