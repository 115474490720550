import React from "react";
import { TFunction, useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import style from "./notification.scss";
import Exit from "components/icons/Exit";
import NotificationIcon from "components/icons/NotificationIcon";
import { createLicenseTypes, getDeliveryTypes } from "components/licenses/common";
import {
    ALL_WORKFLOWS_ROUTE,
    DELIVERYHISTORY_LICENSES_ROUTE,
    LICENSES_ALL_LICENSES_ROUTE,
    RouteDefinition,
} from "components/router/Routes";
import Heading from "components/typography/heading/Heading";
import { TextBlock } from "components/typography/textBlock/TextBlock";
import { SUPPORT_EMAIL } from "domain/globalConstants";
import { Notification, NotificationType } from "domain/notification";
import {
    ReportDeletionNotificationData,
    toReportDeletionNotificationData,
} from "services/report/ReportDeletionService";
import { ImportNotificationData, toImportNotificationData } from "services/report/ReportImportService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { TenantDeletionNotificationData } from "services/tenants/TenantService";
import { Profile, profileToNameMap } from "services/workflows/WorkflowService";
import { StoreState } from "store";
import { formatTimestamp } from "utils/format";

import testIds from "testIds.json";

const mapState = (state: StoreState) => ({
    theme: state.themeReducer.theme,
});

const connector = connect(mapState);

const forwardToRoute = (route: RouteDefinition) => {
    window.location.replace(route.path);
};

const LICENSE_TYPES = createLicenseTypes(true);

const getLicenseType = (notificationData: string) => {
    const license = LICENSE_TYPES.find((license) => license.productId === notificationData);
    return license != null ? license.productName : notificationData;
};

const deriveLinkBlock = (clickHandler: () => void, linkText: string) => {
    return (
        <button
            className={style.link}
            data-testid={testIds.navigation.notificationMenu.notification.navigationLink}
            onClick={clickHandler}
        >
            {linkText}
        </button>
    );
};

const deriveNotificationLink = (props: Props, t: TFunction) => {
    const notificationType = props.notification.type;
    if (notificationType === "NEW_LICENSE_DELIVERY") {
        return deriveLinkBlock(() => {
            forwardToRoute(DELIVERYHISTORY_LICENSES_ROUTE);
        }, t("Notification.link.viewDeliveries"));
    }
    if (["LICENSE_RAN_OUT", "LICENSE_EXPIRED"].includes(notificationType)) {
        return deriveLinkBlock(() => {
            forwardToRoute(LICENSES_ALL_LICENSES_ROUTE);
        }, t("Notification.link.viewLicenses"));
    }
    if (
        [
            "REPORT_IMPORT_IN_PROGRESS",
            "REPORT_IMPORT_DONE",
            "REPORT_IMPORT_DONE_WITH_FAILURES",
            "REPORT_IMPORT_FAILED",
        ].includes(notificationType)
    ) {
        return deriveLinkBlock(() => {
            usageStatisticsService.sendEvent({
                category: Category.REPORT_IMPORT,
                action: Action.SEE_DETAILS,
                label: props.notification.type,
            });
            props.showImportReportDialog(toImportNotificationData(JSON.parse(props.notification.data)));
        }, t("Notification.link.seeDetails"));
    }
    if (
        ["REPORT_EXPORT_SUCCEEDED", "REPORT_EXPORT_STARTED", "REPORT_EXPORT_WARNING", "REPORT_EXPORT_FAILED"].includes(
            notificationType
        )
    ) {
        return deriveLinkBlock(() => {
            props.showExportReport(props.notification.data);
        }, t("Notification.link.seeDetails"));
    }
    if (
        [
            "REPORT_DELETION_STARTED",
            "REPORT_DELETION_SUCCEEDED",
            "REPORT_DELETION_FAILED",
            "REPORT_DELETION_WARNING",
        ].includes(notificationType)
    ) {
        return deriveLinkBlock(() => {
            props.showDeleteReport(toReportDeletionNotificationData(JSON.parse(props.notification.data)));
        }, t("Notification.link.seeDetails"));
    }
    if (["FEATURE_UPDATE", "WORKFLOW_EDITOR_UPDATE"].includes(notificationType)) {
        return deriveLinkBlock(() => {
            forwardToRoute(ALL_WORKFLOWS_ROUTE);
        }, t("Notification.link.viewWorkflows"));
    }
    return <div />;
};

const deriveSupportEmailLink = (notificationData: string) => {
    const tenantName = JSON.parse(notificationData).tenant_name;
    const subject = encodeURIComponent(
        "Blancco Management Portal - Unexpected Error while deleting tenant: " + tenantName
    );
    const body = encodeURIComponent(
        "Hi there\n\n" +
            "I've experienced a persistent issue with deleting tenant: " +
            tenantName +
            "\nWould you be able to assist?\n\n"
    );
    return `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;
};

const deriveHeading = (
    notificationType: NotificationType,
    notificationData: string,
    t: TFunction,
    deliveryTypeToTranslation?: Map<string, string>
) => {
    switch (notificationType) {
        case "NEW_LICENSE_DELIVERY":
            return t("Notification.title.newLicenseDelivery", {
                type: deliveryTypeToTranslation?.get(notificationData),
            });
        case "LICENSE_RAN_OUT":
            return t("Notification.title.licenseRanOut", { license: getLicenseType(notificationData) });
        case "LICENSE_EXPIRED":
            return t("Notification.title.licenseExpired", { license: getLicenseType(notificationData) });
        case "ALERT_TRIGGERED":
            return notificationData.includes("tenant_name") ? JSON.parse(notificationData).tenant_name : "";
        case "REPORT_IMPORT_IN_PROGRESS":
            return t("Notification.title.reportImport.inProgress.title");
        case "REPORT_IMPORT_DONE":
            return t("Notification.title.reportImport.done.title");
        case "REPORT_IMPORT_DONE_WITH_FAILURES":
            return t("Notification.title.reportImport.doneWithFailures.title");
        case "REPORT_IMPORT_FAILED":
            return t("Notification.title.reportImport.failed.title");
        case "REPORT_EXPORT_STARTED":
            return t("Notification.title.reportExport.reportExportInProgress");
        case "REPORT_EXPORT_SUCCEEDED":
            return t("Notification.title.reportExport.reportExportSuccess");
        case "REPORT_EXPORT_WARNING":
            return t("Notification.title.reportExport.reportExportSuccessWithException");
        case "REPORT_EXPORT_FAILED":
            return t("Notification.title.reportExport.reportExportFailed");
        case "REPORT_DELETION_STARTED":
            return t("Notification.title.reportDeletion.reportDeletionInProgress", {
                count: JSON.parse(notificationData).total_reports,
            });
        case "REPORT_DELETION_SUCCEEDED":
            return t("Notification.title.reportDeletion.reportDeletionSuccess", {
                count: JSON.parse(notificationData).total_reports,
            });
        case "REPORT_DELETION_WARNING":
            return t("Notification.title.reportDeletion.reportDeletionSuccessWithException");
        case "REPORT_DELETION_FAILED":
            return t("Notification.title.reportDeletion.reportDeletionFailed");
        case "REPORT_EDIT_IN_PROGRESS":
            return t("Notification.title.reportEdit.inProgress", { uuid: JSON.parse(notificationData).report_uuid });
        case "REPORT_EDIT_SUCCEEDED":
            return t("Notification.title.reportEdit.done", { uuid: JSON.parse(notificationData).report_uuid });
        case "REPORT_EDIT_FAILED":
            return t("Notification.title.reportEdit.failed", { uuid: JSON.parse(notificationData).report_uuid });
        case "TENANT_DELETION_IN_PROGRESS":
            return t("Notification.title.tenantDeletion.inProgress.title", {
                customerName: JSON.parse(notificationData).tenant_name,
            });
        case "TENANT_DELETION_SUCCEEDED":
            return t("Notification.title.tenantDeletion.succeeded.title", {
                customerName: JSON.parse(notificationData).tenant_name,
            });
        case "TENANT_DELETION_FAILED":
            return t("Notification.title.tenantDeletion.failed.title", {
                customerName: JSON.parse(notificationData).tenant_name,
            });
        case "FEATURE_UPDATE":
            return t("Notification.title.featureUpdate.title");
        default:
            return notificationType;
    }
};

const deriveMessage = (notificationType: NotificationType, notificationData: string, t: TFunction) => {
    switch (notificationType) {
        case "REPORT_IMPORT_IN_PROGRESS":
            return t("Notification.title.reportImport.inProgress.message");
        case "REPORT_IMPORT_DONE":
            return t("Notification.title.reportImport.done.message");
        case "REPORT_IMPORT_DONE_WITH_FAILURES":
            return t("Notification.title.reportImport.doneWithFailures.message");
        case "REPORT_IMPORT_FAILED":
            return t("Notification.title.reportImport.failed.message");
        case "TENANT_DELETION_IN_PROGRESS":
            return t("Notification.title.tenantDeletion.inProgress.message");
        case "TENANT_DELETION_FAILED":
            return t("Notification.title.tenantDeletion.failed.message");
        case "FEATURE_UPDATE":
            return t("Notification.title.featureUpdate.message");
        case "WORKFLOW_EDITOR_UPDATE": {
            const parsed = JSON.parse(notificationData);
            return t("Notification.title.workflowEditorUpdate.message", {
                version: parsed.version,
                product: profileToNameMap.get(parsed.profile.toLowerCase() as Profile),
            });
        }
        default:
            return "";
    }
};
const deriveContent = (props: Props, t: TFunction) => {
    switch (props.notification.type) {
        case "REPORT_IMPORT_IN_PROGRESS":
        case "REPORT_IMPORT_DONE":
        case "REPORT_IMPORT_DONE_WITH_FAILURES":
        case "REPORT_IMPORT_FAILED": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t, getDeliveryTypes())}
                    </Heading>
                    <TextBlock disableBottomSpacing={true}>
                        {deriveMessage(props.notification.type, props.notification.data, t)}
                    </TextBlock>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "REPORT_EXPORT_SUCCEEDED":
        case "REPORT_EXPORT_STARTED":
        case "REPORT_EXPORT_WARNING":
        case "REPORT_EXPORT_FAILED": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t)}
                    </Heading>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "REPORT_DELETION_STARTED":
        case "REPORT_DELETION_SUCCEEDED":
        case "REPORT_DELETION_FAILED":
        case "REPORT_DELETION_WARNING": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t)}
                    </Heading>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "REPORT_EDIT_IN_PROGRESS":
        case "REPORT_EDIT_SUCCEEDED":
        case "REPORT_EDIT_FAILED": {
            return (
                <Heading tag="div" variant="SUBTITLE_2">
                    {deriveHeading(props.notification.type, props.notification.data, t)}
                </Heading>
            );
        }
        case "TENANT_DELETION_SUCCEEDED":
        case "TENANT_DELETION_IN_PROGRESS":
        case "TENANT_DELETION_FAILED": {
            const heading = deriveHeading(props.notification.type, props.notification.data, t);
            const message = deriveMessage(props.notification.type, props.notification.data, t);
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {heading}
                    </Heading>
                    {message && (
                        <TextBlock disableBottomSpacing={true}>
                            {deriveMessage(props.notification.type, props.notification.data, t)}
                        </TextBlock>
                    )}
                    <div>
                        {props.notification.type === "TENANT_DELETION_FAILED" && (
                            <a href={deriveSupportEmailLink(props.notification.data)} className={style.link}>
                                {t("DeleteTenant.supportLink")}
                            </a>
                        )}
                    </div>
                </>
            );
        }
        case "FEATURE_UPDATE": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t)}
                    </Heading>
                    <TextBlock disableBottomSpacing={true}>
                        {deriveMessage(props.notification.type, props.notification.data, t)}
                    </TextBlock>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "ALERT_TRIGGERED": {
            let notificationMessage;
            if (props.notification.data.includes("amount_threshold")) {
                notificationMessage = t("Notification.title.alertTriggered.succeeded.amount_threshold_message", {
                    tenant_name: JSON.parse(props.notification.data).tenant_name,
                    amount_threshold: JSON.parse(props.notification.data).amount_threshold,
                    product_name: JSON.parse(props.notification.data).product_name,
                });
            } else if (props.notification.data.includes("expiration_threshold")) {
                notificationMessage = t("Notification.title.alertTriggered.succeeded.expiration_threshold_message", {
                    tenant_name: JSON.parse(props.notification.data).tenant_name,
                    expiration_threshold: JSON.parse(props.notification.data).expiration_threshold,
                    product_name: JSON.parse(props.notification.data).product_name,
                });
            }
            return (
                <>
                    <TextBlock disableBottomSpacing={true}>{notificationMessage}</TextBlock>
                    <button
                        className={style.link}
                        data-testid={testIds.navigation.notificationMenu.notification.itself}
                        onClick={() => {
                            usageStatisticsService.sendEvent({
                                category: Category.LICENSE_ALERTS,
                                action: Action.VIEW_LICENSE_ALERTS,
                                label: props.notification.type,
                            });
                            if (props.notification.data.includes("alert_uuid")) {
                                const alert_uuid = JSON.parse(props.notification.data).alert_uuid;
                                props.showLicenseAlertDialog ? props.showLicenseAlertDialog(alert_uuid) : "";
                            }
                        }}
                    >
                        <div className={style.reportImportNotificationIcon}>{t("Notification.link.seeDetails")}</div>
                    </button>
                </>
            );
        }
        case "NEW_LICENSE_DELIVERY": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t, getDeliveryTypes())}
                    </Heading>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "LICENSE_EXPIRED":
        case "LICENSE_RAN_OUT": {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t)}
                    </Heading>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        case "WORKFLOW_EDITOR_UPDATE": {
            return (
                <>
                    <TextBlock disableBottomSpacing={true}>
                        {deriveMessage(props.notification.type, props.notification.data, t)}
                    </TextBlock>
                    <div>{deriveNotificationLink(props, t)}</div>
                </>
            );
        }
        default: {
            return (
                <>
                    <Heading tag="div" variant="SUBTITLE_2">
                        {deriveHeading(props.notification.type, props.notification.data, t)}
                    </Heading>
                </>
            );
        }
    }
};

interface Props {
    notification: Notification;
    key: number;
    onDelete: (uuid: string) => void;
    showImportReportDialog: (jobData: ImportNotificationData) => void;
    showExportReport: (jobId: string) => void;
    showDeleteReport: (jobId: ReportDeletionNotificationData) => void;
    showDeleteTenant?: (tenantName: TenantDeletionNotificationData) => void;
    showLicenseAlertDialog?: (uuid: string) => void;
}

const NotificationBlock = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    return (
        <>
            <div
                key={props.key}
                className={style.menuItem}
                data-testid={testIds.navigation.notificationMenu.notification.itself}
                data-type={props.notification.type}
            >
                <div className={style.reportImportNotificationIcon}>
                    <NotificationIcon
                        notificationType={props.notification.type}
                        testid={testIds.navigation.notificationMenu.notification.statusIcon}
                    />
                </div>
                <div className={style.notification}>
                    <div className={style.notificationBody}>{deriveContent(props, t)}</div>
                    <div className={style.timestamp}>{formatTimestamp(props.notification.creationDate)}</div>
                </div>
                <div className={style.removeButtonContainer}>
                    <button
                        className={style.removeButton}
                        data-testid={testIds.navigation.notificationMenu.notification.deleteButton}
                        onClick={() => props.onDelete(props.notification.uuid)}
                    >
                        <Exit color={props.theme.iconFillColor} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default connector(NotificationBlock);
