import classNames from "classnames";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

import { ButtonContainer } from "components/button-container/ButtonContainer";
import Button from "components/button/Button";
import ErrorMessage from "components/error-message/ErrorMessage";
import BdeSteps from "components/home/bde-home-page/BdeSteps";
import style from "components/home/bde-home-page/getting-started.scss";
import BmdeSteps from "components/home/bmde-home-page/BmdeSteps";
import { BmpSetup } from "components/home/BmpSetup";
import ProgressBar from "components/home/ProgressBar";
import IconButton from "components/icon-button/IconButton";
import LeftArrow from "components/icons/LeftArrow";
import { subpageContext } from "components/layout/ApplicationLayout";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import Heading from "components/typography/heading/Heading";
import {
    AUTH_REPORT_VIEW,
    AUTH_WORKFLOW_DEFAULT,
    AUTH_WORKFLOW_TEMPLATE_SAVE,
    AUTH_WORKFLOW_VIEW,
} from "domain/authority";
import { LicenseData } from "domain/licenses";
import {
    BdeOnboardingSteps,
    BmdeOnboardingSteps,
    displayProgressBarProgress,
    getInitialProgressPercentage,
    getStepIndexForBde,
    getStepIndexForBmde,
    mapCompletedStepToBdeOnboardingStep,
    mapCompletedStepToBmdeOnboardingStep,
    OnboardingProductType,
} from "domain/onboardings";
import { SoftwarePackage, Versions } from "domain/softwarePackages";
import { FeatureLicenseType } from "domain/users";
import { ConnectedWorkflows } from "domain/workflows";
import { TemplateTableData } from "domain/workflowTemplates";
import { Onboarding, onboardingsService } from "services/onboardings/OnboardingsService";
import { userSessionService } from "services/user/UserSessionService";
import { templateService } from "services/workflows/TemplateService";
import { EditorGeneration, Profile } from "services/workflows/WorkflowService";
import buttons from "styles/buttons.scss";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface Props {
    product: OnboardingProductType;
    visible: boolean;
    title: string;
    extraHeaderText?: string;
    description: string;
    footerVisibility?: boolean;
    buttons?: React.ReactNode;
    children?: React.ReactNode;
    primaryButtonRequired?: boolean;
    handleRefresh?: (value: boolean) => void;
    fetchedSoftwarePackages?: SoftwarePackage[];
    licenses: Versions[];
    fetchedLicenses: LicenseData[];
    fromPage?: string;
    saveBmdeOnboarded?: (onboarded: boolean) => void;
    saveBdeOnboarded?: (onboarded: boolean) => void;
}

export const GettingStartedLayout = (props: Props) => {
    const { t } = useTranslation();
    const context = React.useContext(subpageContext);

    const theme = useTheme();

    const hasFeatureLicense = () => {
        return userSessionService.hasFeatureLicense("FEATURE_REPORTING");
    };
    const canViewReport = userSessionService.userHasAnyAuthority([AUTH_REPORT_VIEW]) && hasFeatureLicense();
    const canViewWorkflows = userSessionService.userHasAllAuthorities([
        AUTH_WORKFLOW_VIEW,
        AUTH_WORKFLOW_DEFAULT,
        AUTH_WORKFLOW_TEMPLATE_SAVE,
    ]);
    const checkIbr = (product: string) => {
        const featureLicense: FeatureLicenseType =
            product === OnboardingProductType.BDE ? "FEATURE_WORKFLOW_BDE" : "FEATURE_WORKFLOW_BMDE";
        return userSessionService.hasFeatureLicense(featureLicense) && canViewWorkflows;
    };
    const [stepInProgressIndex, setStepInProgressIndex] = React.useState(-1);
    const [lastCompletedStep, setLastCompletedStep] = React.useState<string>("");
    const [showBmpSetup, setShowBmpSetup] = React.useState<boolean>(false);
    const bmdeStep = React.useCallback((): BmdeOnboardingSteps | undefined => {
        if (props.product === OnboardingProductType.BMDE) {
            return mapCompletedStepToBmdeOnboardingStep(lastCompletedStep, checkIbr(OnboardingProductType.BMDE));
        }
    }, [lastCompletedStep, stepInProgressIndex]);
    const bdeStep = React.useCallback((): BdeOnboardingSteps | undefined => {
        if (props.product === OnboardingProductType.BDE) {
            return mapCompletedStepToBdeOnboardingStep(lastCompletedStep, checkIbr(OnboardingProductType.BDE));
        }
    }, [lastCompletedStep, stepInProgressIndex]);
    const lastCompletedBmdeStep = !bmdeStep()
        ? -1
        : getStepIndexForBmde(bmdeStep(), checkIbr(OnboardingProductType.BMDE));
    const lastCompletedBdeStep = !bdeStep ? -1 : getStepIndexForBde(bdeStep(), checkIbr(OnboardingProductType.BDE));
    const [lastCompletedStepIndexBmde, setLastCompletedStepIndexBmde] = React.useState<number>(-1);
    const [lastCompletedStepIndexBde, setLastCompletedStepIndexBde] = React.useState<number>(-1);
    React.useEffect(() => {
        setLastCompletedStepIndexBmde(lastCompletedBmdeStep);
    }, [lastCompletedBmdeStep]);
    React.useEffect(() => {
        setLastCompletedStepIndexBde(lastCompletedBdeStep);
    }, [lastCompletedBdeStep]);
    const [progressValueBmde, setProgressValueBmde] = React.useState<number>(
        getInitialProgressPercentage(lastCompletedStepIndexBmde, checkIbr(OnboardingProductType.BMDE))
    );
    React.useEffect(() => {
        if (lastCompletedBmdeStep === lastCompletedStepIndexBmde) {
            setProgressValueBmde(
                getInitialProgressPercentage(lastCompletedStepIndexBmde, checkIbr(OnboardingProductType.BMDE))
            );
        } else {
            setProgressValueBmde(
                displayProgressBarProgress(
                    lastCompletedStepIndexBmde,
                    progressValueBmde,
                    checkIbr(OnboardingProductType.BMDE)
                )
            );
        }
    }, [lastCompletedStepIndexBmde]);
    const [progressValueBde, setProgressValueBde] = React.useState<number>(
        getInitialProgressPercentage(lastCompletedStepIndexBde, checkIbr(OnboardingProductType.BDE))
    );
    React.useEffect(() => {
        if (lastCompletedBdeStep === lastCompletedStepIndexBde) {
            setProgressValueBde(
                getInitialProgressPercentage(lastCompletedStepIndexBde, checkIbr(OnboardingProductType.BDE))
            );
        } else {
            setProgressValueBde(
                displayProgressBarProgress(
                    lastCompletedStepIndexBde,
                    progressValueBde,
                    checkIbr(OnboardingProductType.BDE)
                )
            );
        }
    }, [lastCompletedStepIndexBde]);
    const connectedWorkflows: ConnectedWorkflows = {
        collectWorkflows: [],
        gradingPanelWorkflows: [],
        remoteWorkflows: 0,
        runDiagnoseWorkflows: [],
        runProcessFlowWorkflows: [],
        subWorkflows: [],
        totalConnectedWorkflows: 0,
        parentWorkflows: [],
    };

    const editorGeneration: EditorGeneration = "V1";
    const profile: Profile = Profile.ALL;
    const defaultTemplateTable: TemplateTableData = {
        connectedWorkflows: connectedWorkflows,
        coreActivities: [],
        created: "",
        creator: "",
        editorGeneration: editorGeneration,
        editorVersion: "",
        modified: "",
        onboarding: false,
        own: false,
        processors: [],
        profile: profile,
        published: false,
        saved: false,
        templateType: "",
        update: false,
        usageCount: 0,
        useCases: [],
        userName: "",
        userUuid: "",
        description: "",
        name: "",
        uuid: "",
    };
    const abortController = new AbortController();
    const [isLoading, setIsLoading] = React.useState(true);
    const [requestFailureMessage, setRequestFailureMessage] = React.useState<string>("");
    const [defaultWorkflowTemplate, setDefaultWorkflowTemplate] =
        React.useState<TemplateTableData>(defaultTemplateTable);
    const [showError, setShowError] = React.useState(false);

    const handleCloseClick = () => {
        context?.setSubpage({
            content: undefined,
        });
    };

    function saveOnboardingStep(onboarding: Onboarding, abortController: AbortController) {
        if (onboarding.product === OnboardingProductType.BDE) {
            setStepInProgressIndex(
                getStepIndexForBde(
                    mapCompletedStepToBdeOnboardingStep(onboarding.step, checkIbr(props.product)),
                    checkIbr(props.product)
                )
            );
        } else {
            setStepInProgressIndex(
                getStepIndexForBmde(
                    mapCompletedStepToBmdeOnboardingStep(onboarding.step, checkIbr(props.product)),
                    checkIbr(props.product)
                )
            );
        }
        onboardingsService
            .saveOnboardingStep(onboarding, abortController)
            .then(() => {
                setStepInProgressIndex(-1);
                setLastCompletedStep(onboarding.step);
                if (
                    onboarding.step === BmdeOnboardingSteps.STEP_VIEWING_REPORT ||
                    onboarding.step === BdeOnboardingSteps.STEP_VIEWING_REPORT
                ) {
                    setShowBmpSetup(true);
                    if (onboarding.product === OnboardingProductType.BMDE && props.saveBmdeOnboarded) {
                        props.saveBmdeOnboarded(true);
                    }

                    if (onboarding.product === OnboardingProductType.BDE && props.saveBdeOnboarded) {
                        props.saveBdeOnboarded(true);
                    }
                }
            })
            .catch(() => {
                setShowError(true);
                setRequestFailureMessage(t("Onboarding.bmde.errorMessages.onboardingStepNotSaved"));
            });
    }

    const fetchOnboardings = (abortController: AbortController) => {
        onboardingsService
            .fetchOnboardings(props.product, abortController)
            .then((onboarding) => {
                if (onboarding.length > 0) {
                    setLastCompletedStep(onboarding[0].step);
                } else {
                    setLastCompletedStep("");
                }
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    React.useEffect(() => {
        fetchOnboardings(abortController);
    }, []);

    const fetchData = (abortController: AbortController) => {
        templateService
            .fetchWorkflowTemplates(
                props.product,
                "",
                "",
                "",
                [],
                [],
                [],
                true,
                "",
                "",
                "",
                false,
                "",
                true,
                abortController
            )
            .then((data) => {
                if (data?.workflowTemplatesTableData.length > 0) {
                    setDefaultWorkflowTemplate(data.workflowTemplatesTableData[0]);
                    setRequestFailureMessage("");
                } else {
                    setDefaultWorkflowTemplate(defaultTemplateTable);
                }
            })
            .catch(() => {
                if (!abortController.signal.aborted) {
                    setRequestFailureMessage(t("workflowTemplatesManager.template.requestFailed"));
                    setShowError(true);
                }
            });
    };
    React.useEffect(() => {
        if (checkIbr(props.product)) {
            fetchData(abortController);
        }
    }, [props.fetchedLicenses]);

    const hideErrorModal = () => {
        setShowError(false);
    };

    function setUpBmp() {
        return showBmpSetup ? <BmpSetup product={props.product} /> : null;
    }

    return (
        <div className={style.pageLayout}>
            {isLoading ? (
                <LoadingIndicator />
            ) : (
                <div>
                    <div className={classNames(style.layoutPadding, style.pageContainer, style.page)}>
                        <div className={style.pageHeader}>
                            {props.extraHeaderText ? (
                                <div className={style.layoutTopText}>{props.extraHeaderText + " /"}</div>
                            ) : null}
                            <div className={style.headingLayout}>
                                <IconButton
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.backToPrimaryViewButton}
                                >
                                    <LeftArrow color={theme.secondaryButtonForegroundColor} />
                                </IconButton>
                                <Heading className={style.headingText} tag="h1" variant="H1">
                                    {props.title}
                                </Heading>
                            </div>
                            <div className={style.layoutTopText}>{props.description}</div>
                        </div>
                        <ProgressBar
                            progressValue={
                                props.product === OnboardingProductType.BMDE ? progressValueBmde : progressValueBde
                            }
                        />
                        <div>
                            {props.product === OnboardingProductType.BDE ? (
                                <BdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBde}
                                    fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                                    defaultWorkflowTemplate={defaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                    fetchedLicenses={props.fetchedLicenses}
                                    canViewReport={canViewReport}
                                    licenses={props.licenses}
                                    saveOnboardingStep={(onboarding: Onboarding) =>
                                        saveOnboardingStep(onboarding, abortController)
                                    }
                                    hasIbr={checkIbr(OnboardingProductType.BDE)}
                                    stepInProgressIndex={stepInProgressIndex}
                                    saveStepInProgressIndex={setStepInProgressIndex}
                                />
                            ) : (
                                <BmdeSteps
                                    lastCompletedStepIndex={lastCompletedStepIndexBmde}
                                    fetchedSoftwarePackages={props.fetchedSoftwarePackages}
                                    licenses={props.licenses}
                                    defaultWorkflowTemplate={defaultWorkflowTemplate}
                                    requestFailureMessage={requestFailureMessage}
                                    fetchedLicenses={props.fetchedLicenses}
                                    canViewReport={canViewReport}
                                    canViewWorkflows={checkIbr(OnboardingProductType.BMDE)}
                                    saveOnboardingStep={(onboarding: Onboarding) =>
                                        saveOnboardingStep(onboarding, abortController)
                                    }
                                    stepInProgressIndex={stepInProgressIndex}
                                    saveStepInProgressIndex={setStepInProgressIndex}
                                />
                            )}
                            {setUpBmp()}
                        </div>
                        <div className={style.pageContent}>
                            <ErrorBoundary FallbackComponent={ErrorMessage}>{props.children}</ErrorBoundary>
                        </div>
                    </div>
                    {props.footerVisibility ? (
                        <div className={classNames(style.layoutPadding, style.pageFooter)}>
                            <ButtonContainer alignment="LEFT" noSpacingTop={true}>
                                {props.buttons}
                                <Button
                                    variant={props.primaryButtonRequired ? "PRIMARY" : "SECONDARY"}
                                    type={"button"}
                                    onClick={handleCloseClick}
                                    testId={testIds.common.subpageView.cancelButton}
                                >
                                    {props.primaryButtonRequired ? t("Common.ok") : t("Common.cancel")}
                                </Button>
                            </ButtonContainer>
                        </div>
                    ) : null}

                    <Modal isOpen={showError} hideModal={hideErrorModal} modalTitle={requestFailureMessage}>
                        <>
                            <div className={style.errorMessageContainer}>
                                {t("Onboarding.bmde.errorMessages.genericError")}
                            </div>
                            <div className={style.errorButtonContainer}>
                                <button
                                    className={classNames(
                                        buttons.primaryButton,
                                        buttons.medium,
                                        buttons.okButton,
                                        buttons.buttonWithoutIcon
                                    )}
                                    onClick={() => {
                                        setShowError(false);
                                    }}
                                >
                                    {t("Common.ok")}
                                </button>
                            </div>
                        </>
                    </Modal>
                </div>
            )}
        </div>
    );
};
