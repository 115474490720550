import type { SnakeCasedPropertiesDeep } from "type-fest";

import { McsResponseDto, McTable, toMcsData } from "domain/mc";
import { apiGatewayService, ApiType } from "services/api/ApiGatewayService";
import { ParameterQuery } from "utils/commonFunctions";

const API_PATH = "/api/v1/mcs";

export interface RegistrationTokenDto {
    token: string;
}

export interface RegistrationToken {
    token: string;
}

function toRegistrationToken(dto: RegistrationTokenDto): RegistrationToken {
    return {
        token: dto.token,
    };
}

export interface SyncInterval {
    desiredIntervals: number[];
    deadlineIntervals: number[];
    selectedDesiredInterval?: number;
    selectedDeadlineInterval?: number;
}
export type SyncIntervalDto = SnakeCasedPropertiesDeep<SyncInterval>;

class HybridMcService {
    public createMcRegistrationToken(): Promise<RegistrationToken> {
        return apiGatewayService
            .invokeApi("/api/v1/mcs/register", "POST", null, {
                apiType: ApiType.OLIVER,
            })
            .then((dto: RegistrationTokenDto) => toRegistrationToken(dto));
    }

    public async fetchData(abortController: AbortController, cursor?: string): Promise<McTable> {
        const url = API_PATH + new ParameterQuery().add("cursor", cursor ? cursor : null).createQueryString();
        return await apiGatewayService
            .invokeApi(url, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
            })
            .then((dto: McsResponseDto) => {
                return {
                    cursor: dto.cursor,
                    desired: dto.desired,
                    mcdata: dto.mcs.map(toMcsData),
                };
            })
            .catch(() => {
                return Promise.reject("Failed to fetch Mcs data.");
            });
    }

    public async resetMc(uuid: string, abortController: AbortController): Promise<string> {
        return await apiGatewayService
            .invokeApi(API_PATH + "/" + uuid + "/reset", "POST", null, {
                apiType: ApiType.OLIVER,
                jsonResponse: false,
                abortSignal: abortController.signal,
            })
            .then((response: Response) => response.text());
    }

    public editMc(
        uuid: string,
        name: string | undefined,
        description: string | undefined,
        abortController: AbortController
    ): Promise<void> {
        const postData = { name, description };
        return apiGatewayService.invokeApi(API_PATH + "/" + uuid, "POST", postData, {
            abortSignal: abortController.signal,
            apiType: ApiType.OLIVER,
            emptyResponse: true,
        });
    }

    public toggleMcActiveStatus(uuid: string, action: "enable" | "disable", signal: AbortSignal): Promise<void> {
        return apiGatewayService.invokeApi(`${API_PATH}/${uuid}/${action}`, "POST", null, {
            apiType: ApiType.OLIVER,
            abortSignal: signal,
            emptyResponse: true,
        });
    }

    public unregisterMc(uuid: string, signal: AbortSignal): Promise<void> {
        return apiGatewayService.invokeApi(`${API_PATH}/${uuid}/unregister`, "POST", null, {
            apiType: ApiType.OLIVER,
            abortSignal: signal,
            emptyResponse: true,
        });
    }

    public fetchIntervals(abortController: AbortController, tenantUuid: string): Promise<SyncInterval> {
        const url = API_PATH + "/intervals" + new ParameterQuery().add("tenant_uuid", tenantUuid).createQueryString();
        return apiGatewayService
            .invokeApi(url, "GET", null, {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
            })
            .then((dto: SyncIntervalDto) => {
                function sortIntervals(intervals: number[]) {
                    return intervals.sort((a, b) => a - b);
                }
                return {
                    desiredIntervals: sortIntervals(dto.desired_intervals),
                    deadlineIntervals: sortIntervals(dto.deadline_intervals),
                    selectedDesiredInterval: dto.selected_desired_interval,
                    selectedDeadlineInterval: dto.selected_deadline_interval,
                };
            });
    }

    public updateIntervals(
        abortController: AbortController,
        tenantUuid: string,
        desired: number,
        deadline: number
    ): Promise<void> {
        const url = API_PATH + "/intervals" + new ParameterQuery().add("tenant_uuid", tenantUuid).createQueryString();
        return apiGatewayService.invokeApi(
            url,
            "POST",
            { desired_interval: desired, deadline_interval: deadline },
            {
                abortSignal: abortController.signal,
                apiType: ApiType.OLIVER,
                emptyResponse: true,
            }
        );
    }
}

export const hybridMcService = new HybridMcService();
