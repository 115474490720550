import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";

import workflowStyle from "./all-workflows-table.scss";
import { useOutsideClick } from "components/header/MenuPanel";
import Chevron from "components/icons/Chevron";
import { ConnectedWorkflows } from "domain/workflows";
import { toUniqueArray } from "utils/commonFunctions";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const FIELD_PARENT_WORKFLOWS = "parentWorkflows";
const FIELD_SUB_WORKFLOWS = "subWorkflows";
const FIELD_RUN_DIAGNOSE_WORKFLOWS = "runDiagnoseWorkflows";
const FIELD_RUN_PROCESSFLOW_WORKFLOWS = "runProcessflowWorkflows";
const FIELD_COLLECT_WORKFLOWS = "collectWorkflows";
const FIELD_GRADING_PANEL_WORKFLOWS = "gradingPanelWorkflows";

interface Props {
    connectedWorkflows: ConnectedWorkflows;
    isSubWorkflow: boolean;
}

export default function ConnectedWorkflowsList(props: Props) {
    const { t } = useTranslation();
    const buttonRef = React.useRef(null);
    const [expanded, setExpanded] = React.useState<boolean>(false);
    const theme = useTheme();
    const createDropdownArrow = (isExpanded: boolean) => {
        return (
            <div>
                <Chevron
                    color={theme.iconFillColor}
                    className={classNames(workflowStyle.toggleArrow, workflowStyle.down, {
                        [workflowStyle.up]: isExpanded,
                    })}
                    width={20}
                    height={20}
                />
            </div>
        );
    };
    const handleClickOutside = () => {
        setExpanded(false);
    };

    const connectedWorkflowsRef = useOutsideClick(handleClickOutside);

    const createConnectedWorkflowsList = (): JSX.Element => {
        const elements: JSX.Element[] = [];
        const connectedWorkflowActivityList = (activity: string, workflows: string[]) => {
            const deduplicated = toUniqueArray(workflows);
            const titles = new Map<string, string>([
                [
                    FIELD_PARENT_WORKFLOWS,
                    props.isSubWorkflow
                        ? t("WorkflowsTable.connectedWorkflows.template")
                        : t("WorkflowsTable.connectedWorkflows.parentWorkflows"),
                ],
                [FIELD_SUB_WORKFLOWS, t("WorkflowsTable.connectedWorkflows.subWorkflows")],
                [FIELD_RUN_DIAGNOSE_WORKFLOWS, t("WorkflowsTable.connectedWorkflows.runDiagnoseWorkflows")],
                [FIELD_RUN_PROCESSFLOW_WORKFLOWS, t("WorkflowsTable.connectedWorkflows.runProcessflowWorkflows")],
                [FIELD_COLLECT_WORKFLOWS, t("WorkflowsTable.connectedWorkflows.collectWorkflows")],
                [FIELD_GRADING_PANEL_WORKFLOWS, t("WorkflowsTable.connectedWorkflows.gradingPanelWorkflows")],
            ]);

            if (deduplicated.length > 0) {
                elements.push(<div className={workflowStyle.title}>{titles.get(activity)}:</div>);
                deduplicated.map((each) => {
                    elements.push(<div className={workflowStyle.workflowName}>{each}</div>);
                });
            }
        };

        connectedWorkflowActivityList(FIELD_PARENT_WORKFLOWS, props.connectedWorkflows.parentWorkflows);
        connectedWorkflowActivityList(FIELD_SUB_WORKFLOWS, props.connectedWorkflows.subWorkflows);
        connectedWorkflowActivityList(FIELD_RUN_DIAGNOSE_WORKFLOWS, props.connectedWorkflows.runDiagnoseWorkflows);
        connectedWorkflowActivityList(
            FIELD_RUN_PROCESSFLOW_WORKFLOWS,
            props.connectedWorkflows.runProcessFlowWorkflows
        );
        connectedWorkflowActivityList(FIELD_GRADING_PANEL_WORKFLOWS, props.connectedWorkflows.gradingPanelWorkflows);
        connectedWorkflowActivityList(FIELD_COLLECT_WORKFLOWS, props.connectedWorkflows.collectWorkflows);

        if (props.connectedWorkflows.remoteWorkflows && props.connectedWorkflows.remoteWorkflows) {
            elements.push(
                <div className={workflowStyle.title}>
                    {t("WorkflowsTable.connectedWorkflows.remoteWorkflows")}: {props.connectedWorkflows.remoteWorkflows}
                </div>
            );
        }

        return (
            <div>
                {elements.map((each, index) => {
                    return <div key={index}>{each}</div>;
                })}
            </div>
        );
    };

    return (
        <div
            className={workflowStyle.connectedWorkflowColumn}
            ref={connectedWorkflowsRef}
            data-testid={testIds.workArea.workflows.table.connectedWorkflows.itself}
        >
            <div
                className={workflowStyle.connectedWorkflows}
                onClick={(e) => {
                    e.stopPropagation();
                    setExpanded((prev) => !prev);
                }}
            >
                <div className={workflowStyle.connectedWorkflowsCount}>
                    {props.connectedWorkflows.totalConnectedWorkflows}
                </div>

                <button
                    ref={buttonRef}
                    data-testid={testIds.workArea.workflows.table.connectedWorkflows.button}
                    aria-expanded={expanded}
                >
                    {createDropdownArrow(expanded)}
                </button>
            </div>
            {expanded && (
                <div
                    data-testid={testIds.workArea.workflows.table.connectedWorkflows.details}
                    className={workflowStyle.listDetails}
                >
                    {createConnectedWorkflowsList()}
                </div>
            )}
        </div>
    );
}
