import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";

import { BmpWorkflowsLogsData, State } from "./BmpWorkfowsLogsData";
import style from "./SidePanelLogs.scss";
import { ALL_REPORTS_ROUTE } from "components/router/Routes";

interface SidePanelLogsProps {
    filter: string;
}

export function SidePanelLogs(props: SidePanelLogsProps): JSX.Element | null {
    const filteredLogs = BmpWorkflowsLogsData.filter((log) => {
        if (props.filter === "All") {
            return true;
        }
        if (props.filter === "In progress") {
            return log.state === State.STARTED;
        }
        if (props.filter === "Failed") {
            return log.state === State.FAILED;
        }
        if (props.filter === "Succeeded") {
            return log.state === State.SUCCEEDED;
        }
        return false;
    });

    return (
        <tbody>
            {filteredLogs.map((log, index) => (
                <tr key={index}>
                    <p>Started: {log.started_date}</p>
                    {log.report_uuid && <p>Report UUID: {log.report_uuid}</p>}
                    {log.state !== State.STARTED && (
                        <p>
                            <Link to={ALL_REPORTS_ROUTE.path}>View report</Link>
                        </p>
                    )}
                    <td
                        className={classNames({
                            [style.extendedStatusSuccessful]: log.state === State.SUCCEEDED,
                            [style.extendedStatusFailed]: log.state === State.FAILED,
                        })}
                    >
                        {getStateText(log.state, log.end_date)}
                    </td>
                </tr>
            ))}
        </tbody>
    );
}

function getStateText(state: State, endDate: string) {
    switch (state) {
        case State.SUCCEEDED:
            return `Succeeded: ${endDate}`;
        case State.FAILED:
            return `Failed: ${endDate}`;
        case State.STARTED:
            return "Running for 1 min";
        default:
            return "";
    }
}
