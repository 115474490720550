import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

import * as LanguageRepository from "services/language/languageRepository";

const LANGUAGE_CODE = LanguageRepository.getLanguage().code;

const LANGUAGES = ["en_US", "fr_FR", "de_DE", "ja_JP", "es_ES", "pt_BR", "zh_CN", "ko_KR"];
const VERSION = process.env.BMP_VERSION ?? "dev";

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: LANGUAGE_CODE,
        preload: [LANGUAGE_CODE],
        fallbackLng: "en_US",
        debug: false,
        supportedLngs: LANGUAGES,
        ns: ["translation", "reportPaths", "diagnosticReasonCodes"],
        backend: {
            loadPath: "../../public/locales/{{lng}}/{{ns}}.json?version=" + VERSION,
        },

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        react: {
            useSuspense: false,
        },
    });

export default i18n;
