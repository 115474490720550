import { TableData } from "./table";
import { DefaultWorkflow, EditorGeneration, Profile } from "services/workflows/WorkflowService";

export interface EditorMetadata {
    profile: Profile;
}

export interface Workflow {
    name: string;
    version: string;
    editorMetadata?: EditorMetadata;
}

export function isWorkflow(candidate: unknown): candidate is Workflow {
    const workflow = candidate as Workflow;
    return workflow.name !== undefined && workflow.version !== undefined;
}

export interface WorkflowsData {
    count: number;
    cursor: string;
    workflowTableData: WorkflowTableData[];
}

export interface ConnectedWorkflowsDto {
    parent_workflows: string[];
    sub_workflows: string[];
    run_diagnose_workflows: string[];
    run_process_flow_workflows: string[];
    remote_workflow_count: number;
    grading_panel_workflows: [];
    collect_workflows: [];
}

export interface ConnectedWorkflows {
    parentWorkflows: string[];
    subWorkflows: string[];
    runDiagnoseWorkflows: string[];
    runProcessFlowWorkflows: string[];
    remoteWorkflows: number;
    gradingPanelWorkflows: string[];
    collectWorkflows: string[];
    totalConnectedWorkflows: number;
}

export interface WorkflowTableData extends TableData {
    name: string;
    uuid: string;
    id: number;
    product: string;
    profile: Profile;
    description: string;
    createdDate: string;
    creator: string;
    defaultWorkflow: DefaultWorkflow;
    workflow: Workflow;
    userUuid: string;
    own: boolean;
    editorVersion: string;
    editorGeneration: EditorGeneration;
    connectedWorkflows: ConnectedWorkflows;
}

export interface VersionsData {
    id: string;
    modified: string;
    modifiedBy: string;
}

export interface FilterState {
    from: string;
    to: string;
}

export type WorkflowEntityType = "WORKFLOW" | "TEMPLATE" | "SUB_WORKFLOW";
