export enum State {
    STARTED,
    SUCCEEDED,
    FAILED,
}

// TODO BCC-4599 This mock data should be removed once the actual data is available
export const BmpWorkflowsLogsData = [
    {
        report_uuid: "d212-2112-12413-13431",
        started_date: "1 June 2025, 08:50",
        state: State.STARTED,
        end_date: "1 June 2025, 09:50",
    },
    {
        started_date: "1 June 2025, 08:50",
        state: State.FAILED,
        end_date: "1 June 2025, 09:50",
    },
    {
        started_date: "1 June 2025, 08:50",
        state: State.SUCCEEDED,
        end_date: "1 June 2025, 09:50",
    },
];
