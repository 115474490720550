import classNames from "classnames";
import { ErrorMessage, Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { toast } from "react-toastify";
import { object, string } from "yup";

import style from "./manage-license-pool.scss";
import MoveLicensesDialog from "./MoveLicensesDialog";
import DeleteConfirmationDialog from "components/confirmation/DeleteConfirmationDialog";
import { CustomToastCloseButton } from "components/icons/CustomToastCloseButton";
import { DeleteIcon } from "components/icons/DeleteIcon";
import Info from "components/icons/Info";
import LeftArrow from "components/icons/LeftArrow";
import { MoveIcon } from "components/icons/MoveIcon";
import SimpleDeleteIcon from "components/icons/SimpleDeleteIcon";
import { SuccessIcon } from "components/icons/SuccessIcon";
import Warning from "components/icons/Warning";
import layoutStyle from "components/layout/layout.scss";
import { displayAmountErrorLabel, isAmountAvailable, isAmountValid } from "components/licenses/common";
import { addMoreLicenses, License } from "components/licenses/delivery-history/DeliveryFormContent";
import { PoolDetails } from "components/licenses/licenses-pool/LicensePoolKebabMenu";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import Modal from "components/modal/Modal";
import warningIcon from "components/reports/report.scss";
import DeleteItemStatusDialog from "components/status-dialog/DeleteItemStatusDialog";
import StaticTable from "components/support/api-guide/StaticTable";
import Tooltip from "components/tooltip/Tooltip";
import Heading from "components/typography/heading/Heading";
import { LicenseInPool, LicensePoolDetails, licensePoolService, toLicense } from "services/licenses/LicensePoolService";
import { Action, Category, usageStatisticsService } from "services/statistics/UsageStatisticsService";
import { StoreState } from "store";
import buttons from "styles/buttons.scss";
import form from "styles/form.scss";
import { formatDateWithoutTime, formatIsoDate } from "utils/format";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

const connector = connect((state: StoreState) => ({
    theme: state.themeReducer.theme,
}));

export interface LicensePoolValues {
    poolName: string;
    notes: string;
    licensesToDeliver: LicenseInPool[];
}

interface Props {
    availableLicenses: LicenseInPool[];
    hideDialogView?: (value: boolean) => void;
    isEdit: (value: boolean) => void;
    poolDetails: PoolDetails;
    edit: boolean;
    onLicensePoolDelete: () => void;
}

interface Result {
    title: string;
    message: string | JSX.Element;
    image?: string | JSX.Element;
}

const MIN_LICENSE_POOL_NAME = 2;
const MAX_LICENSE_POOL_NAME = 32;
const NOTES_MAX_LENGTH = 128;
export const DEFAULT_LICENSE = "default";

const ManageLicensePoolView = (props: Props & ConnectedProps<typeof connector>): JSX.Element => {
    const { t } = useTranslation();
    const abortController = new AbortController();
    const theme = useTheme();
    const { current: abortControllers } = React.useRef<AbortController[]>([]);
    const [licensesUpdated, setLicensesUpdated] = React.useState<boolean>(false);
    const defaultSelectedLicense = {
        licenseType: DEFAULT_LICENSE,
        licensesToAdd: 0,
        productName: t("LicensePools.view.licenses.table.defaultLicenses"),
        expirationDate: formatIsoDate(new Date()),
        index: 0,
        available: 0,
        assigned: 0,
        remaining: 0,
        amount: 0,
        totalAvailable: 0,
    };

    const deduceSelectedLicenses = (): LicenseInPool[] => {
        if (!props.edit) {
            return [defaultSelectedLicense];
        }
        const deducedLicenses: LicenseInPool[] = [];
        props.availableLicenses.map((each) => {
            const foundLicense = props.poolDetails.licenses.find((item) => item.licenseId == each.licenseType);
            if (foundLicense) {
                each["licensesToAdd"] = foundLicense?.totalOfLicenses || 0;
                each["assigned"] = foundLicense.totalOfLicenses;
                each["remaining"] = foundLicense.amount;
                each["amount"] = foundLicense.amount;
                each["totalAvailable"] = foundLicense.totalOfLicenses + each["available"];
                deducedLicenses.push(each);
            }
        });
        if (deducedLicenses.length == 0) {
            deducedLicenses.push(defaultSelectedLicense);
        }
        return deducedLicenses;
    };
    const [selectedLicenses, setSelectedLicenses] = React.useState<LicenseInPool[]>([]);
    const [moveLicensesAllowed, setMoveLicensesAllowed] = React.useState<boolean>(false);
    React.useEffect(() => {
        setSelectedLicenses(deduceSelectedLicenses());
    }, [props.availableLicenses]);

    React.useEffect(() => {
        setLicensesUpdated(false);
        if (selectedLicenses.length !== props.poolDetails.licenses.length) {
            setLicensesUpdated(true);
            return;
        }
        const licenseWithAmount = props.poolDetails.licenses.filter(
            (each) => each.licenseId !== DEFAULT_LICENSE && each.amount > 0
        );
        setMoveLicensesAllowed(licenseWithAmount.length > 0);
        selectedLicenses.forEach((selectedLicense) => {
            props.poolDetails.licenses.forEach((poolLicense) => {
                if (
                    poolLicense.licenseId === selectedLicense.licenseType &&
                    poolLicense.totalOfLicenses !== selectedLicense.licensesToAdd
                ) {
                    setLicensesUpdated(true);
                }
            });
        });
    }, [selectedLicenses]);

    const [loading, setLoading] = React.useState<boolean>(false);
    const [showFailureDialog, setShowFailureDialog] = React.useState<boolean>(false);
    const [moveLicensesDialog, setMoveLicensesDialog] = React.useState<boolean>(false);

    function deduceLicensesToDeliver(selectedLicenses: LicenseInPool[]): LicensePoolDetails[] {
        return selectedLicenses.map((each) => {
            return {
                type: each.licenseType,
                amount: each.licensesToAdd,
            };
        });
    }

    const submitHandler: FormikConfig<LicensePoolValues>["onSubmit"] = async (values, { setSubmitting, setErrors }) => {
        setSubmitting(false);
        values.licensesToDeliver = selectedLicenses.filter((each) => each.licenseType !== DEFAULT_LICENSE);
        if (values.poolName != null) {
            setLoading(true);
            const matchingLicensePool =
                props.poolDetails.name != values.poolName
                    ? await licensePoolService.fetchLicensePools(abortController, values.poolName.trim())
                    : { licensePools: [] };
            if (matchingLicensePool.licensePools.length !== 0) {
                setLoading(false);
                setErrors({
                    poolName: t("LicensePools.create.alreadyExists"),
                });
                return;
            }
        }
        const editRequest = {
            poolUuid: props.poolDetails.uuid,
            poolName: values.poolName == props.poolDetails.name ? props.poolDetails.name : values.poolName,
            notes: values.notes == props.poolDetails.notes ? props.poolDetails.notes : values.notes,
            licenses: deduceLicensesToDeliver(values.licensesToDeliver),
        };

        const createRequest = {
            poolName: values.poolName,
            notes: values.notes,
            licenses: deduceLicensesToDeliver(values.licensesToDeliver),
        };
        abortControllers.push(abortController);
        const { signal } = abortController;

        props.edit
            ? licensePoolService
                  .editLicensePool(editRequest, abortController)
                  .then(() => {
                      setSubmitting(true);
                      props.hideDialogView ? props.hideDialogView(false) : undefined;
                      toast(
                          <SuccessIcon
                              successClass={layoutStyle.customToastSuccessIcon}
                              color={theme.contentBackgroundColor}
                              text={t("LicensePools.edit.success")}
                          />,
                          {
                              closeButton: (closeToastProps) => (
                                  <CustomToastCloseButton
                                      closeToast={{ ...closeToastProps }}
                                      color={theme.iconFillColor}
                                  />
                              ),
                              className: layoutStyle.customToastSuccessMessage,
                          }
                      );
                      props.isEdit(false);
                  })
                  .catch(() => {
                      props.isEdit(true);
                      if (!signal.aborted) {
                          setShowFailureDialog(true);
                          setSubmitting(false);
                      }
                  })
                  .finally(() => {
                      setLoading(false);
                  })
            : licensePoolService
                  .createLicensePool(createRequest, abortController)
                  .then(() => {
                      setSubmitting(true);
                      props.hideDialogView ? props.hideDialogView(false) : undefined;
                      toast(
                          <SuccessIcon
                              successClass={layoutStyle.customToastSuccessIcon}
                              color={theme.contentBackgroundColor}
                              text={t("LicensePools.create.success")}
                          />,
                          {
                              closeButton: (closeToastProps) => (
                                  <CustomToastCloseButton
                                      closeToast={{ ...closeToastProps }}
                                      color={theme.iconFillColor}
                                  />
                              ),
                              className: layoutStyle.customToastSuccessMessage,
                          }
                      );
                  })
                  .catch(() => {
                      if (!signal.aborted) {
                          setSubmitting(false);
                          setShowFailureDialog(true);
                      }
                  })
                  .finally(() => {
                      setLoading(false);
                  });
    };

    const addRow = () => {
        usageStatisticsService.sendEvent({
            category: Category.LICENSE_POOLS,
            action: Action.ADD_LICENSE_TO_POOL,
        });
        const newAdded: LicenseInPool = {
            licenseType: defaultSelectedLicense.licenseType,
            expirationDate: selectedLicenses[selectedLicenses.length - 1].expirationDate,
            productName: defaultSelectedLicense.productName,
            available: defaultSelectedLicense.available,
            licensesToAdd: defaultSelectedLicense.licensesToAdd,
            index: Math.max(...selectedLicenses.map((item) => item.index)) + 1,
            assigned: defaultSelectedLicense.assigned,
            remaining: defaultSelectedLicense.remaining,
            amount: defaultSelectedLicense.amount,
            totalAvailable: defaultSelectedLicense.available,
        };
        const licenses = selectedLicenses.concat([newAdded]);
        setSelectedLicenses(licenses);
    };

    const [showWarning, setShowWarning] = React.useState<boolean>(false);

    const removeRow = (index: number) => {
        setSelectedLicenses(selectedLicenses.filter((each) => each.index !== index));
    };

    const failureDialog = () => {
        return (
            <>
                <Modal isOpen={showFailureDialog} hideModal={() => setShowFailureDialog(false)}>
                    <div className={style.modalTitle}>
                        <Warning color={theme.errorBackgroundColor} />
                    </div>
                    <div className={style.modalTitle}>
                        <Heading tag="h2">
                            {props.edit ? t("LicensePools.edit.failure.title") : t("LicensePools.create.failure.title")}
                        </Heading>
                    </div>
                    <div className={style.resultContainer}>{t("LicensePools.supportMessage")}</div>
                    <div className={classNames(buttons.buttonContainer, style.errorDialogButtons)}>
                        <button
                            type={"button"}
                            className={buttons.primaryButtonWithoutIcon}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(true) : undefined;
                                setShowFailureDialog(false);
                                setLoading(false);
                            }}
                            data-testid={testIds.common.dialog.retryButton}
                        >
                            {props.edit
                                ? t("LicensePools.edit.failure.button")
                                : t("LicensePools.create.failure.button")}
                        </button>
                        <button
                            className={buttons.secondaryButtonWithoutIcon}
                            data-testid={testIds.common.dialog.closeButton}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(false) : undefined;
                                setShowFailureDialog(false);
                                setLoading(false);
                                props.isEdit(false);
                            }}
                        >
                            {t("Common.cancel")}
                        </button>
                    </div>
                </Modal>
            </>
        );
    };

    const warningOnCancel = () => {
        return (
            <Modal
                isOpen={showWarning}
                hideModal={() => setShowWarning(false)}
                modalTitle={t("LicensePools.cancel.title")}
            >
                <div>
                    <div className={form.resultContainer}>{t("LicensePools.cancel.warning")}</div>
                    <div className={classNames(style.float, style.warningButtonContainer)}>
                        <button
                            type={"button"}
                            className={buttons.primaryButtonWithoutIcon}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(true) : undefined;
                                props.edit ? props.isEdit(true) : props.isEdit(false);
                                setShowWarning(false);
                            }}
                            data-testid={testIds.common.dialog.closeButton}
                        >
                            {t("LicensePools.cancel.keepEditing")}
                        </button>
                        <button
                            className={buttons.secondaryButtonWithoutIcon}
                            data-testid={testIds.common.confirmationDialog.confirmButton}
                            onClick={() => {
                                props.hideDialogView ? props.hideDialogView(false) : undefined;
                                props.isEdit(false);
                                setShowWarning(false);
                            }}
                        >
                            {t("LicensePools.cancel.dontSave")}
                        </button>
                    </div>
                </div>
            </Modal>
        );
    };

    const checkForLicensePoolDeletion = props.poolDetails ? props.poolDetails.assignedUsers < 1 : null;
    const [result, setResult] = React.useState<Result>({ title: "", message: "", image: "" });
    const [deleteLicensePoolModalVisible, setDeleteLicensePoolModalVisible] = React.useState(false);
    const [licensePoolNotDeleted, setLicensePoolNotDeleted] = React.useState<boolean>(false);
    const handleLicensePoolDelete = () => {
        deleteLicensePoolSubmitEventHandler();
        setLicensePoolNotDeleted(false);
        props.hideDialogView ? props.hideDialogView(false) : undefined;
    };
    const [okClicked, setOkClicked] = React.useState<boolean>(false);
    const showResult = (resultToShow: Result) => {
        setResult(resultToShow);
    };

    const deleteLicensePoolSubmitEventHandler = () => {
        const abortController = new AbortController();
        abortControllers.push(abortController);
        const { signal } = abortController;
        licensePoolService
            .deleteLicensePool(props.poolDetails ? props.poolDetails.uuid : "", abortController)
            .then(() => {
                props.onLicensePoolDelete();
                toast(
                    <SuccessIcon
                        successClass={layoutStyle.customToastSuccessIcon}
                        color={theme.contentBackgroundColor}
                        text={t("LicensePools.deleteLicensePool.deletedMessage", {
                            poolName: props.poolDetails?.name,
                        })}
                    />,
                    {
                        closeButton: (closeToastProps) => (
                            <CustomToastCloseButton closeToast={{ ...closeToastProps }} color={theme.iconFillColor} />
                        ),
                        className: layoutStyle.customToastSuccessMessage,
                    }
                );
                props.isEdit(false);
            })
            .catch(() => {
                props.isEdit(true);
                if (!signal.aborted) {
                    showResult({
                        title: t("LicensePools.deleteLicensePool.licensePoolNotDeletedTitle"),
                        message: <span>{t("LicensePools.deleteLicensePool.failureMessage")}</span>,
                        image: <img className={warningIcon.image} />,
                    });
                }
                setLicensePoolNotDeleted(true);
            })
            .finally(() => {
                setOkClicked(false);
                setDeleteLicensePoolModalVisible(false);
            });
    };

    const deduceSelectedLicensesCount = (
        license: LicenseInPool,
        e: React.ChangeEvent<HTMLInputElement>
    ): LicenseInPool[] => {
        return selectedLicenses.map((each) => {
            if (each.index === license.index) {
                const amount = Math.abs(parseInt(e.target.value));
                const allLicenses = props.poolDetails.licenses.find((item) => item.licenseId == each.licenseType);
                each.remaining = isNaN(amount) ? allLicenses?.amount ?? 0 : amount - each.assigned + (each.amount ?? 0);
                each.licensesToAdd = amount;
                each.available = isNaN(amount) ? each.totalAvailable : each.totalAvailable - amount;
            }
            return each;
        });
    };

    const deduceUsedSelectedLicenseCount = (license: License): number => {
        let usedCount = 0;
        const licensePool = props.poolDetails.licenses.find((item) => license.licenseType == item.licenseId);
        if (licensePool) {
            usedCount = licensePool.used;
        }
        return usedCount;
    };

    const disableSubmit = (dirty: boolean) => {
        return (
            !dirty ||
            selectedLicenses.some(
                (eachCurrentLicense) =>
                    !(
                        isAmountAvailable(toLicense(eachCurrentLicense), true) &&
                        isAmountValid(
                            toLicense(eachCurrentLicense),
                            deduceUsedSelectedLicenseCount(toLicense(eachCurrentLicense)),
                            true
                        )
                    )
            )
        );
    };

    return (
        <>
            <Formik
                validateOnBlur={true}
                initialValues={
                    props.edit
                        ? {
                              poolName: props.poolDetails.name,
                              notes: props.poolDetails.notes,
                              licensesToDeliver: selectedLicenses,
                          }
                        : { poolName: "", notes: "", licensesToDeliver: [defaultSelectedLicense] }
                }
                onSubmit={submitHandler}
                validationSchema={object().shape({
                    poolName: string()
                        .required(t("LicensePools.validation.poolName"))
                        .min(
                            MIN_LICENSE_POOL_NAME,
                            t("LicensePools.validation.poolNameMinLength", {
                                length: MIN_LICENSE_POOL_NAME,
                            })
                        )
                        .max(
                            MAX_LICENSE_POOL_NAME,
                            t("LicensePools.validation.poolNameMaxLength", {
                                length: MAX_LICENSE_POOL_NAME,
                            })
                        ),
                })}
                validateOnChange={false}
            >
                {({ values, errors, dirty, handleChange }: FormikProps<LicensePoolValues>) => {
                    const loader = loading ? (
                        <div className={style.loaderContainer}>
                            <LoadingIndicator small={true} />
                        </div>
                    ) : null;
                    const isChanged = dirty || licensesUpdated;
                    return (
                        <Form className={style.form}>
                            {!(showWarning || showFailureDialog) ? (
                                <>
                                    <div className={style.container}>
                                        <div className={style.scroller}>
                                            <div className={style.viewTitle}>
                                                <div className={style.backArrow}>
                                                    <button
                                                        onClick={(e) => {
                                                            isChanged
                                                                ? setShowWarning(true)
                                                                : props.hideDialogView
                                                                ? (props.hideDialogView(false), props.isEdit(false))
                                                                : props.isEdit(false),
                                                                e.preventDefault();
                                                        }}
                                                    >
                                                        <LeftArrow color={props.theme.activeForegroundColor} />
                                                    </button>
                                                </div>
                                                <div className={style.heading}>
                                                    <Heading tag={"h1"}>
                                                        {props.edit
                                                            ? t("LicensePools.edit.title")
                                                            : t("LicensePools.create.title")}
                                                    </Heading>
                                                    {props.edit && !isChanged ? (
                                                        <span className={style.noChanges}>
                                                            {t("LicensePools.edit.noChanges")}
                                                        </span>
                                                    ) : undefined}
                                                </div>
                                            </div>
                                            <div className={form.formFields}>
                                                <label htmlFor="poolName" className={form.label}>
                                                    {t("LicensePools.view.poolName")}
                                                </label>
                                                <input
                                                    id="poolName"
                                                    className={classNames(form.input, form.fixedWidthInput, {
                                                        [form.inputError]: errors.poolName,
                                                    })}
                                                    onChange={handleChange}
                                                    value={values.poolName}
                                                    data-testid={
                                                        testIds.workArea.license.licensePools.manageLicensePoolView
                                                            .poolNameInput.itself
                                                    }
                                                />
                                                {loader}
                                                <div
                                                    className={classNames(form.error, style.errorMessage)}
                                                    data-testid={
                                                        testIds.workArea.license.licensePools.manageLicensePoolView
                                                            .poolNameInput.errorLabel
                                                    }
                                                >
                                                    <ErrorMessage name="poolName" />
                                                </div>
                                            </div>
                                            <div className={classNames(form.formFields, form.formFieldsFlex)}>
                                                <div className={form.formFieldsAlignItemsTop}>
                                                    <span className={form.optional}>{t("Common.optional")}</span>
                                                    <label htmlFor="notes" className={form.label}>
                                                        {t("LicensePools.view.notes")}
                                                    </label>
                                                </div>
                                                <div className={classNames(style.gridRows, form.optional)}>
                                                    <textarea
                                                        id="notes"
                                                        className={classNames(
                                                            form.input,
                                                            form.fixedWidthInput,
                                                            form.textAreaHeight
                                                        )}
                                                        onChange={handleChange}
                                                        data-testid={
                                                            testIds.workArea.license.licensePools.manageLicensePoolView
                                                                .poolNotes.itself
                                                        }
                                                        maxLength={NOTES_MAX_LENGTH}
                                                        value={values.notes}
                                                    />
                                                    <span className={form.notes}>
                                                        {t("LicensePools.validation.notes", {
                                                            remainingCharacters: (
                                                                NOTES_MAX_LENGTH - (values.notes?.length ?? 0)
                                                            ).toString(),
                                                            maximumNumberOfCharacters: NOTES_MAX_LENGTH.toString(),
                                                        })}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className={classNames(form.formFields, style.licensesTable)}>
                                                <label htmlFor="licenses" className={form.label}>
                                                    {t("LicensePools.view.licenses.title")}
                                                </label>
                                                <div
                                                    className={classNames(style.staticTable, {
                                                        [style.inputError]: errors.licensesToDeliver,
                                                    })}
                                                    data-testid={
                                                        testIds.workArea.license.licensePools.manageLicensePoolView
                                                            .table.itself
                                                    }
                                                >
                                                    <StaticTable
                                                        tableClass={errors.licensesToDeliver ? style.inputError : ""}
                                                        headers={[
                                                            {
                                                                value: t(
                                                                    "LicensePools.view.licenses.table.licenseType"
                                                                ),
                                                            },
                                                            {
                                                                value: t("LicensePools.view.licenses.table.available"),
                                                            },
                                                            {
                                                                value: t("LicensePools.view.licenses.table.total"),
                                                            },
                                                            {
                                                                value: t("LicensePools.view.licenses.table.remaining"),
                                                            },
                                                            {
                                                                value: (
                                                                    <Tooltip
                                                                        content={t(
                                                                            "LicensePools.view.licenses.table.tooltip"
                                                                        )}
                                                                    >
                                                                        <span className={style.info}>
                                                                            <div className={style.infoText}>
                                                                                {t(
                                                                                    "LicensePools.view.licenses.table.expirationDate"
                                                                                )}
                                                                            </div>
                                                                            <Info
                                                                                borderColor={
                                                                                    theme.contentBackgroundColor
                                                                                }
                                                                                color={theme.iconFillColor}
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                ),
                                                            },
                                                            {
                                                                value: "",
                                                            },
                                                        ]}
                                                        cells={selectedLicenses.map((license) => {
                                                            return [
                                                                <Tooltip
                                                                    key={license.licenseType}
                                                                    content={license.productName}
                                                                >
                                                                    <select
                                                                        className={classNames(
                                                                            form.select,
                                                                            style.fixedWidthInput,
                                                                            style.ellipsis,
                                                                            style.selectArrow
                                                                        )}
                                                                        id={"licenseType" + license.index}
                                                                        name={"licenseType" + license.index}
                                                                        key={"licenseType" + license.index}
                                                                        onChange={(e) => {
                                                                            const selected =
                                                                                props.availableLicenses.find(
                                                                                    (item) =>
                                                                                        item.licenseType ===
                                                                                        e.target.value
                                                                                );
                                                                            if (selected !== undefined) {
                                                                                setSelectedLicenses(
                                                                                    selectedLicenses.map((each) => {
                                                                                        if (
                                                                                            license.index === each.index
                                                                                        ) {
                                                                                            each = selected;
                                                                                            each.index = license.index;
                                                                                            const selectedLicensesLength =
                                                                                                selectedLicenses.length;
                                                                                            if (
                                                                                                selectedLicensesLength >=
                                                                                                    1 &&
                                                                                                each.available == 0 &&
                                                                                                license.licenseType ===
                                                                                                    "default"
                                                                                            ) {
                                                                                                each.expirationDate =
                                                                                                    selectedLicenses[
                                                                                                        selectedLicensesLength -
                                                                                                            1
                                                                                                    ].expirationDate;
                                                                                            } else {
                                                                                                each.licensesToAdd =
                                                                                                    each.amount;
                                                                                            }
                                                                                        }
                                                                                        return each;
                                                                                    })
                                                                                );
                                                                            }
                                                                        }}
                                                                        onClick={() => {
                                                                            usageStatisticsService.sendEvent({
                                                                                category: Category.LICENSE_POOLS,
                                                                                action: Action.CHANGE_LICENSE_TYPE,
                                                                            });
                                                                        }}
                                                                        value={license.licenseType}
                                                                        data-testid={
                                                                            testIds.workArea.license.licensePools
                                                                                .manageLicensePoolView.table
                                                                                .licenseTypeSelect.itself
                                                                        }
                                                                    >
                                                                        <option value={"default"}>
                                                                            {t(
                                                                                "LicensePools.view.licenses.table.defaultLicenses"
                                                                            )}
                                                                        </option>
                                                                        {props.availableLicenses.map((each) => (
                                                                            <option
                                                                                key={each.licenseType}
                                                                                value={each.licenseType}
                                                                                defaultValue={license.licenseType}
                                                                                hidden={
                                                                                    each.licenseType !==
                                                                                        license.licenseType &&
                                                                                    selectedLicenses.some(
                                                                                        (item) =>
                                                                                            item.licenseType ===
                                                                                            each.licenseType
                                                                                    )
                                                                                }
                                                                            >
                                                                                {each.productName}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                </Tooltip>,
                                                                <div
                                                                    key={"available" + license.index}
                                                                    className={style.label}
                                                                    data-testid={
                                                                        testIds.workArea.license.licensePools
                                                                            .manageLicensePoolView.table.availableLabel
                                                                    }
                                                                >
                                                                    {license.available}
                                                                </div>,
                                                                <div key={"assigned" + license.index}>
                                                                    <input
                                                                        autoFocus
                                                                        className={classNames(
                                                                            form.input,
                                                                            style.inputWidth
                                                                        )}
                                                                        key={"added" + license.index}
                                                                        id={"added" + license.index}
                                                                        type={"number"}
                                                                        onChange={(e) => {
                                                                            setSelectedLicenses(
                                                                                deduceSelectedLicensesCount(license, e)
                                                                            );
                                                                        }}
                                                                        value={license.licensesToAdd.toString()}
                                                                        data-testid={
                                                                            testIds.workArea.license.licensePools
                                                                                .manageLicensePoolView.table
                                                                                .assignedAmountInput.itself
                                                                        }
                                                                    />
                                                                    {displayAmountErrorLabel(
                                                                        toLicense(license),
                                                                        t,
                                                                        true,
                                                                        deduceUsedSelectedLicenseCount(
                                                                            toLicense(license)
                                                                        )
                                                                    )}
                                                                </div>,
                                                                <div key={"remaining" + license.index}>
                                                                    {license.remaining}
                                                                </div>,
                                                                <div
                                                                    key={"expirationDate" + license.index}
                                                                    className={style.gridColumns}
                                                                >
                                                                    <div
                                                                        key={"expirationDate" + license.index}
                                                                        className={style.gridRows}
                                                                    >
                                                                        {license.licenseType != "default"
                                                                            ? formatDateWithoutTime(
                                                                                  license.expirationDate
                                                                              )
                                                                            : ""}
                                                                    </div>
                                                                </div>,
                                                                <div key={"deleteIcon" + license.index}>
                                                                    {selectedLicenses.length > 1 ? (
                                                                        <div
                                                                            key={"deleteRow" + license.index}
                                                                            className={style.margin}
                                                                            onClick={() => {
                                                                                usageStatisticsService.sendEvent({
                                                                                    category: Category.LICENSE_POOLS,
                                                                                    action: Action.REMOVE_LICENSE,
                                                                                    label: license.licenseType,
                                                                                });
                                                                                removeRow(license.index);
                                                                            }}
                                                                            data-testid={
                                                                                testIds.workArea.license.licensePools
                                                                                    .manageLicensePoolView.table
                                                                                    .removeRowButton
                                                                            }
                                                                        >
                                                                            <DeleteIcon
                                                                                color={
                                                                                    props.theme.contentBackgroundColor
                                                                                }
                                                                                linecolor={props.theme.iconFillColor}
                                                                            />
                                                                        </div>
                                                                    ) : (
                                                                        ""
                                                                    )}
                                                                </div>,
                                                            ];
                                                        })}
                                                    />
                                                </div>
                                                <div
                                                    className={classNames(style.error, style.errorMessage)}
                                                    data-testid={
                                                        testIds.workArea.license.licensePools.manageLicensePoolView
                                                            .table.errorLabel
                                                    }
                                                ></div>
                                                <span className={classNames(style.addMoreLicenses)}>
                                                    <div
                                                        onClick={addRow}
                                                        className={style.link}
                                                        data-testid={
                                                            testIds.workArea.license.licensePools.manageLicensePoolView
                                                                .table.addLicenseButton
                                                        }
                                                    >
                                                        {addMoreLicenses(
                                                            props.theme.contentBackgroundColor,
                                                            props.theme.iconFillColor,
                                                            t("Common.addMoreLicenses")
                                                        )}
                                                    </div>
                                                    {props.edit && moveLicensesAllowed ? (
                                                        <div
                                                            className={style.moveButton}
                                                            onClick={() => {
                                                                setMoveLicensesDialog(true);
                                                                usageStatisticsService.sendEvent({
                                                                    category: Category.LICENSE_POOLS,
                                                                    action: Action.MOVE_LICENSES_BETWEEN_POOLS,
                                                                });
                                                            }}
                                                            data-testid={
                                                                testIds.workArea.license.licensePools
                                                                    .manageLicensePoolView.table.moveLicensesButton
                                                            }
                                                        >
                                                            <MoveIcon linecolor={theme.iconFillColor} />
                                                            <span className={style.moveIcon}>
                                                                {t("LicensePools.edit.moveButton")}
                                                            </span>
                                                        </div>
                                                    ) : undefined}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={classNames(style.buttonContainer, buttons.buttonContainer)}>
                                        <button
                                            className={buttons.primaryButtonWithoutIcon}
                                            type="submit"
                                            disabled={disableSubmit(isChanged)}
                                            data-testid={testIds.common.confirmationDialog.confirmButton}
                                        >
                                            {props.edit ? t("Common.save") : t("Common.create")}
                                        </button>
                                        <button
                                            className={buttons.secondaryButtonWithoutIcon}
                                            type="reset"
                                            data-testid={testIds.common.dialog.closeButton}
                                            onClick={(e) => {
                                                isChanged
                                                    ? setShowWarning(true)
                                                    : props.hideDialogView
                                                    ? (props.hideDialogView(false), props.isEdit(false))
                                                    : props.isEdit(false),
                                                    e.preventDefault();
                                            }}
                                        >
                                            {t("Common.cancel")}
                                        </button>
                                        {props.edit ? (
                                            <button
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if (checkForLicensePoolDeletion) {
                                                        setDeleteLicensePoolModalVisible(true);
                                                        usageStatisticsService.sendEvent({
                                                            category: Category.LICENSE_POOLS,
                                                            action: Action.DELETE_LICENSE_POOL,
                                                        });
                                                    }
                                                    e.preventDefault;
                                                }}
                                            >
                                                <SimpleDeleteIcon
                                                    color={
                                                        checkForLicensePoolDeletion
                                                            ? props.theme.errorBackgroundColor
                                                            : theme.disabledButtonBackgroundColor
                                                    }
                                                />
                                                <span
                                                    className={
                                                        checkForLicensePoolDeletion
                                                            ? classNames(
                                                                  style.paddingRight,
                                                                  style.paddingLeft,
                                                                  style.errorTextColor
                                                              )
                                                            : classNames(
                                                                  style.paddingRight,
                                                                  style.paddingLeft,
                                                                  theme.disabledButtonBackgroundColor
                                                              )
                                                    }
                                                >
                                                    {t("LicensePools.deleteLicensePool.delete")}
                                                </span>
                                            </button>
                                        ) : null}
                                    </div>
                                </>
                            ) : showWarning ? (
                                <>{warningOnCancel()}</>
                            ) : (
                                <>{failureDialog()}</>
                            )}
                        </Form>
                    );
                }}
            </Formik>
            <Modal
                isOpen={moveLicensesDialog}
                hideModal={() => setMoveLicensesDialog(false)}
                modalTitle={t("LicensePools.move.title")}
            >
                <MoveLicensesDialog
                    poolDetails={props.poolDetails}
                    availableLicenses={props.availableLicenses.map(toLicense)}
                    setMoveLicensesDialog={setMoveLicensesDialog}
                    isEdit={props.isEdit}
                    hideDialogView={props.hideDialogView}
                    edit={true}
                />
            </Modal>
            <DeleteItemStatusDialog
                title={result.title}
                modalDisplayed={licensePoolNotDeleted}
                setModalDisplayed={() => setLicensePoolNotDeleted(false)}
                handleCommand={deleteLicensePoolSubmitEventHandler}
                ditchLink={true}
            />
            <DeleteConfirmationDialog
                modalDisplayed={deleteLicensePoolModalVisible}
                introductionMessage={
                    <div className={style.resultContainer}>
                        {t("LicensePools.deleteLicensePool.introductionMessage", {
                            poolName: props.poolDetails?.name,
                        })}
                    </div>
                }
                confirmationMessage={t("LicensePools.deleteLicensePool.confirmationMessage")}
                handleCommand={handleLicensePoolDelete}
                setModalDisplayed={() => setDeleteLicensePoolModalVisible(false)}
                title={t("LicensePools.deleteLicensePool.title")}
                loading={okClicked}
            />
        </>
    );
};

export default connector(ManageLicensePoolView);
