import classNames from "classnames";
import React from "react";

import style from "./quick-filter-button.scss";

interface FilterProps {
    filter: string;
    isActive: boolean;
    onClick: () => void;
    testId: string | undefined;
}

const QuickFilterButton = (props: FilterProps) => {
    return (
        <>
            <div
                onClick={props.onClick}
                className={classNames(style.quickFilterButton, { [style.active]: props.isActive })}
                data-testid={props.testId}
            >
                {props.filter}
            </div>
        </>
    );
};

export default QuickFilterButton;
