import React from "react";

import { DOT } from "domain/globalConstants";

interface SidePanelInfoProps {
    connectedWorkflows: Map<string, string[]>;
    product: string;
    description: string;
}

export function SidePanelInfo(props: SidePanelInfoProps): JSX.Element | null {
    let totalConnectedWorkflows = 0;
    for (const workflowArray of props.connectedWorkflows.values()) {
        totalConnectedWorkflows += workflowArray.length;
    }
    const descriptionLength: number = props.description ? props.description.trim().length : 0;

    return (
        <div>
            <div>
                <small>
                    {props.product}
                    {totalConnectedWorkflows > 0 && " " + DOT + ` ${totalConnectedWorkflows} connected workflows`}
                </small>
            </div>
            {/* TODO BCC-4599 Use actual data for description */}
            <div>{descriptionLength > 0 && <b>Description</b>}</div>
            <p>{descriptionLength > 0 ? props.description : ""}</p>
            {totalConnectedWorkflows > 0 && <b>Connected Workflows</b>}
            {Array.from(props.connectedWorkflows.entries()).map(
                ([key, workflowArray]) =>
                    workflowArray.length > 0 && (
                        <div key={key}>
                            <small>{key}</small>
                            {workflowArray.map((workflow, index) => (
                                <p key={index}>{workflow}</p>
                            ))}
                        </div>
                    )
            )}
        </div>
    );
}
