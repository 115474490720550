import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { Row } from "react-table";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

import style from "./side-panel.scss";
import { SidePanelInfo } from "./SidePanelInfo";
import { SidePanelLogs } from "./SidePanelLogs";
import Exit from "components/icons/Exit";
import { LoadingIndicator } from "components/loading-indicator/LoadingIndicator";
import QuickFilterButton from "components/table/QuickFilterButton";
import { Profile } from "services/workflows/WorkflowService";
import { useTheme } from "utils/useTheme";

import testIds from "testIds.json";

interface SidePanelProps {
    isSidePanelOpen: boolean;
    onClose: () => void;
    selectedRow: Row | undefined;
    testId: string;
}

// TODO BCC-4599 Change Active workflow column

export function SidePanel(props: SidePanelProps): JSX.Element | null {
    if (!props.isSidePanelOpen) {
        return null;
    }

    const filters = ["All", "In progress", "Failed", "Succeeded"];
    const theme = useTheme();
    const [activeButton, setActiveButton] = React.useState<string | null>("All");
    const [loading, setLoading] = useState<boolean>(true);

    // TODO BCC-4599 Remove this once the backend is ready
    useEffect(() => {
        if (props.selectedRow) {
            setLoading(true);
            const timer = setTimeout(() => {
                setLoading(false);
            }, 1500);

            return () => clearTimeout(timer);
        }
    }, [props.selectedRow]);

    const handleFilterClick = (filter: string) => {
        setActiveButton(filter);
    };

    const filterTestIds = new Map<string, string>([
        ["All", testIds.workArea.workflows.sidePanel.tabs.log.quickFilterButtons.all],
        ["In progress", testIds.workArea.workflows.sidePanel.tabs.log.quickFilterButtons.inProgress],
        ["Failed", testIds.workArea.workflows.sidePanel.tabs.log.quickFilterButtons.failed],
        ["Succeeded", testIds.workArea.workflows.sidePanel.tabs.log.quickFilterButtons.succeeded],
    ]);

    const panelClassNames = classNames(style.sidePanel, { [style.open]: props.isSidePanelOpen });

    const isBmpWorkflow = props.selectedRow?.cells[1]?.value?.toLowerCase() === Profile.BMP;

    const connectedWorkflows = new Map<string, string[]>([
        ["Parent workflows", props.selectedRow?.cells[4].value.parentWorkflows || []],
        ["Sub-workflows", props.selectedRow?.cells[4].value.subWorkflows || []],
        ["Diagnostic workflows", props.selectedRow?.cells[4].value.runDiagnoseWorkflows || []],
    ]);

    return (
        <>
            <div className={panelClassNames} data-testid={testIds}>
                {/* TODO BCC-4599 Use translations */}

                <h2>{props.selectedRow?.cells[0]?.value ?? "Unknown Workflow"}</h2>
                <p>{isBmpWorkflow && "Trigger: report upload"}</p>

                {isBmpWorkflow ? (
                    <Tabs>
                        <TabList>
                            {isBmpWorkflow && (
                                <Tab data-testid={testIds.workArea.workflows.sidePanel.tabs.log.itself}>{"Log"}</Tab>
                            )}
                            <Tab data-testid={testIds.workArea.workflows.sidePanel.tabs.info.itself}>{"Info"}</Tab>
                        </TabList>

                        {isBmpWorkflow && (
                            <TabPanel>
                                <div>
                                    <div
                                        data-testid={
                                            testIds.workArea.workflows.sidePanel.tabs.log.quickFilterButtons.itself
                                        }
                                        className={style.quickFilterButtonsContainer}
                                    >
                                        {filters.map((filter) => (
                                            <QuickFilterButton
                                                onClick={() => {
                                                    handleFilterClick(filter);
                                                }}
                                                key={filter}
                                                filter={filter}
                                                isActive={activeButton === filter}
                                                testId={filterTestIds.get(filter)}
                                            />
                                        ))}
                                    </div>
                                    {/* TODO BCC-4599 Fetch values dynamically */}
                                    {loading ? <LoadingIndicator /> : <SidePanelLogs filter={activeButton || "All"} />}
                                </div>
                            </TabPanel>
                        )}
                        <TabPanel>
                            {/* TODO BCC-4599 Fetch values dynamically */}
                            <SidePanelInfo
                                connectedWorkflows={connectedWorkflows}
                                product={props.selectedRow?.cells[1]?.value}
                                description={props.selectedRow?.cells[6]?.value}
                            />
                        </TabPanel>
                    </Tabs>
                ) : (
                    <SidePanelInfo
                        connectedWorkflows={connectedWorkflows}
                        product={props.selectedRow?.cells[1]?.value}
                        description={props.selectedRow?.cells[6]?.value}
                    />
                )}

                <button role="close" onClick={props.onClose} data-testid={testIds.common.dialog.xButton}>
                    <Exit color={theme.iconFillColor} />
                </button>
            </div>
        </>
    );
}

export default SidePanel;
